import React from 'react';
import { useNavigate } from 'react-router-dom';

import CalendarCard from '../Components/CalendarCard';
import HpHighlightsCards from '../Components/HpHighlightsCards';
import InformationCard from '../Components/InformationCard';

import Header from '../Components/Header';
import {getLocalStorageItem} from '../Helpers/Storage';
import Fetcher from '../Helpers/Fetcher';

import {Link} from "react-router-dom";
import {BsChevronRight} from 'react-icons/bs';

class Home extends React.Component{
  constructor(props) {
    super(props);
    this.state = { 
      notifications: [{ id: 'id', title: "Test Push", body: "This is my first push notification" }],
      error: false,
      isLoaded: false, calIsLoaded: false, formationIsLoaded: false,
      cards:[],
      highlights: [],
      first_informations: [],
      meetings:[], 
      formations:[], 
      informations:[],
      svgurl:process.env.REACT_APP_SITE_URL,
      showDistributeur:false
    };
  }
  componentDidMount(){
  
    getLocalStorageItem('fmt_user_info').then((r)=>JSON.parse(r)).then((res) => {
      let showDistributeur = false;
      if(res !== null){
          if(res.type_company === 'distributeur') showDistributeur = true;
      }
      this.setState({
        showDistributeur:showDistributeur,
        firstname:res.firstname_user
      });

    });

    //etLocalStorageItem('fmt_user_info').then((ret) => JSON.parse(ret)).then((r) => {this.setState({})});
    this.fetchHomeApi();
    this.fetchAvailableFormationApi();
  }

  fetchHomeApi = () => {    
    new Fetcher().callApi({
        api_path:'/home/get',
        api_data:{},
        method:'POST',
    }).then((r) => {
        if(r.status === 'success'){
          this.setState({
            isLoaded: true,
            highlights: ((r.results.highlights !== undefined)?r.results.highlights:{}),
            first_informations: ((r.results.first_informations !== undefined)?r.results.first_informations:{}),
            informations: r.results.informations,
          });
          //Cascade API calls to avoid token issue
          this.fetchCalendarApi();
        }
        else {
            this.props.navigate('/error');
        }
    });
  }   
  fetchCalendarApi = () => {  
    new Fetcher().callApi({
        api_path:'/calendar/get',
        api_data:{  
          "nb_items": 2
        },
        method:'POST',
    }).then((r) => {
        if(r.status === 'success'){
          this.setState({
            calIsLoaded: true,
            meetings: r.results
          });

        }
        else {
          this.setState({calIsLoaded: true,error:true})
        }
    });
  }
  fetchAvailableFormationApi = () => {  
    new Fetcher().callApi({
        api_path:'/calendar/list_available_formation',
        api_data:{  
          "nb_items": 2
        },
        method:'POST',
    }).then((r) => {
        if(r.status === 'success'){
          this.setState({
            formationIsLoaded: true,
            formations: r.results
          });

        }
        else {
          this.setState({calIsLoaded: true,error:true})
        }
    });
  }
  gotopage = (p) => {
    this.props.navigate('/'+p);
  }
  render(){
    return (
      <div id="fmt_home" className="container general_spacing h-100 overflow-auto">   
        
               
            <Header Title={"Bonjour "+this.state.firstname+"!"} rightCta={{showHelp:true}} />

            <div className="evCards highlights">
              {(() => {
                if(this.state.highlights.length !== 0){
                  return(
                    Object.values(this.state.highlights).map((item,key) => {
                        return (
                          <HpHighlightsCards key={'hh'+key} item={item} />
                        )
                    })
                  )
                }
              })()}
            </div>
 
            <div className="evCards mt-3">
              <div id="infoCards" className="row">
              {(() => {
                if(this.state.first_informations.length !== 0){
                  return(
                    Object.values(this.state.first_informations).map((item,key) => {
                        return (
                          <div className="col-6 mt-2" key={'fi_'+key} id="cc_qr_code">
                            <InformationCard key={'fi'+key} item={item} />
                          </div>
                        )
                    })
                  )
                }
              })()}
              </div>
            </div>


            <div id="calCardsCont" className="evCards mt-4 pb-1">
              <div className="d-flex justify-content-between">
                <h2 className="">Prochain meetings</h2>
                <Link to="/calendar" className="chevron_link">Calendrier <BsChevronRight /></Link>
              </div>
              <div className="calCards mt-3">
              {(() => {
                if(this.state.meetings.length !== 0){
                  return(
                    Object.values(this.state.meetings).map((item,key) => {
                        const ces = item.cal_timestamp_start;
                        const ced = item.cal_timestamp_end;
                        return (
                          <div key={"cc_"+key} id={"cc_"+key} onClick={() => this.gotopage('calendar')}>
                            <CalendarCard item={item} ces={ces} ced={ced} />
                          </div>
                        )
                    })
                  )
                }
                else {
                  return <p className="my-4">Aucun meeting</p>
                }
              })()}
              </div>
            </div>

            {(() => {
                if(this.state.showDistributeur && this.state.formations.length !== 0){
                  return (
                    <div id="calCardsCont" className="evCards mt-4 pb-1">
                      <div className="d-flex justify-content-between">
                        <h2 className="">Formations disponibles</h2>
                        <Link to="/calendar/free_formation" className="chevron_link">Voir <BsChevronRight /></Link>
                      </div>
                      <div className="calCards mt-3"  onClick={() => this.gotopage('calendar/free_formation')}>
                          {
                            Object.values(this.state.formations).map((item,key) => {
                                const ces = item.cal_timestamp_start;
                                const ced = item.cal_timestamp_end;
                                return (
                                  <div key={"cc_"+key} id={"cc_"+key}>
                                    <CalendarCard item={item} ces={ces} ced={ced} type="free_formation" />
                                  </div>
                                )
                            })
                          }                         
                      </div>
                    </div>
                  )                  
                }
                })()}
                
            <div className="evCards mt-3">
                <div className="row">
                  <div className="col">
                    <h2>Information</h2>
                  </div>
                </div>
              
                <div id="infoCards" className="row">
                  {Object.values(this.state.informations).map((item_inf,key_inf) => {
                      return (
                        <div className="col-6 mt-2" key={"ccc_"+key_inf} id={"ccc_"+key_inf}>
                            <InformationCard item={item_inf} />
                        </div>       
                      )
                    })}
                  </div>
              </div>  
              
      </div>
    )
  }
};
const withLocation = Component => props => {
  const navigate = useNavigate();

  return <Component {...props} navigate={navigate} />;
};

export default withLocation(Home);
