import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Browser } from '@capacitor/browser';


class InformationCard extends React.Component{
    constructor(props) {
        super(props);

    }
    openLink = (i) => {
        if(i.link !== '') this.props.navigation(i.link);
        if(i.linkExternal !== '')  this.openExternal(i.linkExternal);
    }
    openExternal = async (url) => {
        await Browser.open({ url: url });
    }
    render(){
        return (
            <div className={"evCard "+this.props.item.className}>
                {(() => {
                    if(this.props.item.pills !== undefined && this.props.item.pills !== 0){
                        return <div className='pills'>{this.props.item.pills}</div>
                    }
                })()}
                <div  className="ctnr text-center" onClick={() => this.openLink(this.props.item)} >
                    <img src={this.props.item.icon} alt="icon" /> <br />
                    <span className="title">{this.props.item.title}</span>
                    {this.props.item.description}
                </div>      
            </div>
        )
    }
};

export default function(props) {
    const navigation = useNavigate();
    return <InformationCard {...props} navigation={navigation} />;
}

