import React from 'react';

import { useNavigate} from 'react-router-dom';

import logo from '../Assets/img/logo_big.png';

import { HiOutlineMail,HiLockClosed } from 'react-icons/hi';

import { Device } from '@capacitor/device';
import { FCM } from "@capacitor-community/fcm";
import {PushNotifications } from '@capacitor/push-notifications';

import {  setLocalStorageItem,deleteLocalStorageItem } from '../Helpers/Storage';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { showToast,showDomError } from '../Helpers/Toast';
import Fetcher from '../Helpers/Fetcher';
import { Browser } from '@capacitor/browser';


class AuthLogin extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.state = {
            email: '',
            password: '',
            toastState: false,
            toastMessage: 'Message',
            action: "Login",
            actionText: "Se connecter",
            //new
            formFields: {email: '', password: ''},
            formErrors: {email: '', password: ''},

        };   
    }
    componentDidMount(){
        deleteLocalStorageItem("fmt_user_info"); //force flush
        SecureStoragePlugin.clear(); //force flush
    }
    handleChange(e) {
        
        const id = e.target.id;
        const value = e.target.value;
        this.setState({
            [id]: value,
            formErrors : {
                ...this.state.formErrors,
                [id]:''
            }
        });
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'is-invalid');
    }
    handleSubmit() {
        //Chech data
        if(this.validateForm()){
            this.login();
            //if(this.state.formAction === 'login') this.login();
            //else this.editChild();
        }        

    }
    validateForm() {   
        let fieldValidationErrors = this.state.formErrors;
        let formIsValid = true;        
        Object.keys(this.state.formFields).forEach(fieldName => {
            var value = this.state[fieldName];
            if(fieldName === 'email'){
                let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : 'error';
                if(!emailValid) formIsValid = false;
            }
            if(fieldName === 'password'){
                let passwordValid = (!value.trim().length)?false:true;
                fieldValidationErrors.password = passwordValid ? '': 'error';
                if(!passwordValid) formIsValid = false;
            }
          })
          this.setState({formErrors: fieldValidationErrors});
          return formIsValid;
    }
    logDeviceInfo = async () => {
        const info = await Device.getInfo();
      
        return info.platform;
    }
    login= () => {
        const url = process.env.REACT_APP_API_URL + '/auth/login';
        const credentials = {
            "email": this.state.email,
            "password": this.state.password
        }
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: JSON.stringify(credentials)
        })
        .then((res) => {
            if(res.status === 200) return res.json();
            else showToast("Error Logging in");            
        })
        .then(
            (result) => {
                if(result.status === 'error') showToast(result.message);
                else if(result.status === 'error_dom') showDomError(result.message);
                else{
                    const login_time = new Date().getTime();
                    result.results.isLogin = true;
                    result.results.login_time = login_time;
                    SecureStoragePlugin.set({key:"fmt_tokens_token", value:result.results.token}).then(success => console.log(success));
                    SecureStoragePlugin.set({key:"fmt_tokens_refresh_token", value:result.results.refresh_token}).then(success => console.log(success));
                    delete result.results.token;
                    delete result.results.refresh_token;
                    setLocalStorageItem("fmt_user_info",JSON.stringify(result.results))
                        .then(
                            () => {
                                //register push noti tkn                                                        
                                //Init push noti
                                this.logDeviceInfo().then((platform) => { 
                                    if(platform !== 'web'){
                                        //Notification check
                                        PushNotifications.checkPermissions().then((res) => {
                                            if (res.receive !== 'granted') {
                                                PushNotifications.requestPermissions().then((res) => {
                                                    if (res.receive === 'denied') {
                                                        showToast('Push Notification permission denied');
                                                    }
                                                    else {
                                                        showToast('Push Notification permission granted');
                                                        this.registerNotification(result.results.type_company);
                                                    }
                                                });
                                            }
                                            else {
                                                this.registerNotification(result.results.type_company);
                                            }
                                        });  
                                    }
                                });
                                if(result.results.type_company === 'hotesse') this.props.navigation('/hotesse');
                                else if (result.results.force_pwd_change === '1') this.props.navigation('/force_change_pwd');
                                else this.props.navigation('/home');

                        });                    
                }
            },
            (error) => {
                console.error(error);                
                showToast(error.toString());

            }
        )
    }
    registerNotification = (type_company) => {
        PushNotifications.register();
        PushNotifications.addListener('registration',
            (push_token) => {
                console.log('-- push token --', push_token);
                //save token
                new Fetcher().callApi({
                    api_path:'/helpers/update_user_meta',
                    api_data:{
                        "metas":{
                            "push_token": push_token.value
                        }
                    },
                    method:'POST',
                }).then((r) => {
                    //TODO add logic to point to the right type
	                if(r.status === 'success'){
                        FCM.subscribeTo({ topic: type_company })
                        .then((rtkn) => console.log(rtkn))
                        .catch((err) => console.log(err));
                        
                        //showToast('Push registration success:' + push_token.value)
                    }
	                //else {showToast('Push registration DB error')}
                });
                
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                //alert('Error on registration: ' + JSON.stringify(error));
            }
        );

    }
    openCapacitorSite = async (url) => {
        await Browser.open({ url: url });
    }
    render() {  
        return (
            <div id="fmt_login" className="bg_blur h-100 container">
                <main className="form-signin">
                        <img className="mb-4" src={logo} alt="" width="90" />
                        <h1 className="h3 mb-5 fw-normal">Formatech</h1>
                        <div id="error_dom" className="alert alert-danger text-start d-none"></div>
                        <div className="form-floating">
                            <input type="email" className={"form-control "+this.errorClass(this.state.formErrors.email)} id="email" placeholder="name@example.com" value={this.state.email}  onChange={this.handleChange} />
                            <label htmlFor="email"><HiOutlineMail /> Email</label>
                        </div>
                        <br />
                        <div className="form-floating">
                            <input type="password" className={"form-control "+this.errorClass(this.state.formErrors.password)} id="password" placeholder="Password" value={this.state.password}  onChange={this.handleChange} />
                            <label htmlFor="password"><HiLockClosed /> Mot de passe</label>
                        </div>

                        <button onClick={() => this.openCapacitorSite(process.env.REACT_APP_SITE_URL+'/auth/forgot_password')} className="btn-text float-end">Mot de passe oublié ?</button>

                        <button className="mt-4 w-100 btn btn-lg btn-primary" onClick={() => this.handleSubmit()}>Se connecter</button>
                        
                </main>
            </div>
        )
    }  
}

export default function(props) {
    const navigation = useNavigate();
    return <AuthLogin {...props} navigation={navigation} />;
}
