import React from 'react';
import {FaMapMarkerAlt,FaPeopleArrows} from 'react-icons/fa';
import {prettyDate2} from '../Helpers/Global';

class CalendarCard extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {};
        //console.log(this.props);
    }
    getMeetingState(tsStart,tsEnd){
        var tsNow = new Date().getTime();
        
        tsStart = parseInt(tsStart);
        let sd = new Date(tsStart);
        sd.setMinutes( sd.getMinutes() +  new Date().getTimezoneOffset());
        tsStart = sd.getTime();
        
        tsEnd = parseInt(tsEnd);
        let sd_end = new Date(tsEnd);
        sd_end.setMinutes( sd_end.getMinutes() +  new Date().getTimezoneOffset());
        tsEnd = sd_end.getTime();
        
        if(tsNow > tsStart && tsNow < tsEnd ) return 'currentlyMeeting scrollToEl';
        else if(tsNow > tsEnd) return 'oldMeeting'; 
        else return 'futureMeeting';
    }

    render(){
        return (         
            <div key={this.props.ces} className={"calCard passed "+ this.getMeetingState(this.props.ces,this.props.ced)+" "+ this.props.item.cal_type} >  
                <div className="row align-items-center my-2">
                    <div className="col-2">
                        <div className="calCardDate">
                            <span>{prettyDate2(this.props.item.cal_start_date)} </span>
                            <span>{prettyDate2(this.props.item.cal_end_date)}</span> 
                        </div>
                    </div>
                    <div className="col-10">
                        <div className="calCardBloc">
                            <div className="calCardTitle">
                                <b>{this.props.item.cal_title}</b> <br />
                                {(() => {
                                    if(this.props.item.cal_type === 'FORMATION'){
                                    return(
                                        <div>
                                            {this.props.item.cal_description} 
                                            <br />
                                        </div>
                                    )
                                    }
                                    if(this.props.item.host_or_attendee === 'attendee' && this.props.item.cal_type !== 'SM'){
                                        return(
                                            <div>
                                                {this.props.item.host_company}
                                            </div>
                                        )
                                    }
                                    if(this.props.item.host_or_attendee === 'attendee' && this.props.item.cal_type === 'SM'){
                                        return(
                                            <div>
                                                {this.props.item.host_first_name} {this.props.item.host_last_name}
                                            </div>
                                        )
                                    }
                                    if(this.props.item.host_or_attendee === 'host' && this.props.item.cal_type === 'SM'){
                                        return(
                                            <div>
                                                {this.props.item.attendee_first_name} {this.props.item.attendee_last_name}
                                            </div>
                                        )
                                    }
                                })()}

                                <div className="calCardIcons">
                                {this.props.item.cal_location !== "" ?  (
                                    <span className="calCardLocation ">
                                    <FaMapMarkerAlt />   
                                    {this.props.item.cal_location}
                                    </span>
                                    ) :(<></>)
                                }

                                {(() => {
                                    if(this.props.type === 'free_formation'){
                                        return (
                                            <span className="calCardLocation">
                                            <FaPeopleArrows />   
                                            {this.props.item.remaining_spot} place(s) restante(s)
                                            </span>
                                        )
                                    }
                                })()}
                                </div>

                            </div>
                        
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default CalendarCard;