import React from 'react';
import Header from '../Components/Header';
import Fetcher from '../Helpers/Fetcher';
import { useNavigate,useParams } from 'react-router-dom';
import{FaCheck,FaPhoneAlt} from 'react-icons/fa';
import {Link} from "react-router-dom";
import { showToast } from '../Helpers/Toast';

class HelpRequests extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            notifications:[],
            message:'',
            formCompleted:false
      };
    }
    componentDidMount = () => {
        this.fetchHelpNotifications();
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.params.uid_notification !== prevProps.params.uid_notification) {
            this.fetchHelpNotifications();
        }
    }
    fetchHelpNotifications = () => {
        new Fetcher().callApi({
            api_path:'/helpers/list_help',
            api_data:{
                uid_notification:this.props.params.uid_notification
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    notifications:r.results
                })
            }
            else {
            }
        });
    }
    markAsTreated = (e,uid_notification) => {
        const a = e.target.classList.contains('done');
        new Fetcher().callApi({
            api_path:'/helpers/notification_mark_treated',
            api_data:{
                uid_notification:uid_notification,
                action:a
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                e.target.classList.toggle('done');
                this.fetchHelpNotifications();
            }
            else {
                showToast('error', 'Une erreur est survenue');
            }
        });
    }
    render() {  
        return (
            <div id="fmt_help" className="container general_spacing h-100 overflow-auto"> 
                <Header Title="Demande d'aide" forceBackUrl={this.props.params.uid_notification !== undefined?'/help_requests':false} />   
                <div className='conCardsCont'>
                    <div className='conCards'>
                        {(() => {
                            if(this.state.notifications.length !== 0){
                            return(
                                Object.values(this.state.notifications).map((item,key) => {
                                    let reads = [];
                                    let treateds = [];
                                    if(item.noti_reads !== null) {
                                        treateds = item.noti_treateds.split(',');
                                        reads = item.noti_reads.split(',');
                                    }
                                    const done = item.noti_treateds.indexOf("=0") !== item.noti_treateds.lastIndexOf("=0") && item.noti_treateds.indexOf("=0") !== -1;
                                    //console.log(done);
                                    return (
                                        <div key={key} className="conCard d-block no_style">
                                            <div className="row align-items-center">
                                                <div className="col-12 text-start">  
                                                    <a href={"tel:"+item.phone} className="btn_contact"><FaPhoneAlt /></a>
                                                    <button className={"btn_check "+(!done?'done':'')} onClick={(e) => this.markAsTreated(e,item.uid_notification)}><FaCheck /></button>
                                                    <Link to={"/help_requests/"+item.uid_notification} className="contact_denomination pb-3">
                                                        {item.notification_message}
                                                    </Link>
                                                    {item.first_name}&nbsp;<span className="uppercase">{item.last_name}</span> <br />
                                                    {item.name_company} - Stand : {item.num_stand} <br />
                                                    <small>{item.notification_date}</small>   
                                                    <br />
                                                    {
                                                        Object.values(treateds).map((iut,kut) => {
                                                            const els = iut.split('=');
                                                            if(els[1] === "0") return '';
                                                            else return <div className='text-success mt-2'>Traité par {els[0]} le {els[1]}</div>             
                                                        })
                                                    }
                                                    {
                                                        Object.values(reads).map((iu,ku) => {
                                                            const elss = iu.split('=');
                                                            if(elss[1] === "0") return '';
                                                            else return <div className='text-warning mt-2'>Vue par  {elss[0]} le {elss[1]}</div>             
                                                        })
                                                    }
                                                            
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )
                            }
                            else {
                                return(
                                    <p>Aucune notification</p>
                                )
                            }
                        })()}
                </div>
            </div>
        </div>
        )
    }  
}
export default function(props) {
    const navigation = useNavigate();
    const params = useParams();
    return <HelpRequests {...props} navigation={navigation} params={params} />;
}