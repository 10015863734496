import React from "react";
import { useNavigate,useLocation } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    const location  = useLocation();
    
    return (
      <Component
      history={history}
      location={location}
      {...props}
        />
    );
  };
  
  return Wrapper;
};

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);