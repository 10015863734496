
import React, { useEffect, useState  } from 'react';
import { Navigate, useLocation , Outlet } from "react-router-dom";
import { Storage } from '@capacitor/storage';
  
export default function RequireAuth() {
  //let auth = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  let location = useLocation();
  useEffect(() => {
    async function checkLoggedIn () {
        const { value } = await Storage.get({ key: 'fmt_user_info' });
        const v = JSON.parse(value);
        if (v !== null && v.isLogin) setIsLoggedIn(true);
        else setIsLoggedIn(false);
    }
    checkLoggedIn();
    //isLogin().then((ret) => {setIsLoggedIn(ret)});
    
  }, [])
  //TODO log time info then check if time without action (update time log with fetcher) is less than a day
  
  if (isLoggedIn === null) return null;
  /*if (!isloggedin){ //!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }*/

  return (
    !isLoggedIn ? (
      <Navigate to="/login" state={{ from: location }} />
    ) : <Outlet />
  )
}