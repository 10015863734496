import React from 'react';

class Error404 extends React.Component {  
    homepage(){
        this.props.history.push('/');
    }
    render() {  
        return (
            <div id="cv_information" className="bg_blur h-100 container">
                <div className="row px-3 pt-5">
                    <div className="col-8">
                        <h2 className="header_title">Page non trouvée</h2>
                    </div>
                    
                    <div className="row px-1 pt-4"> 
                        <div className="col-12">
                           
                            <button className="btn btn-primary" onClick={() => this.homepage()}>Retour</button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }  
}

export default Error404;