import React from 'react';
import {Link} from "react-router-dom";
import Header from '../Components/Header';
import {getLocalStorageItem,setLocalStorageItem} from '../Helpers/Storage';
import Fetcher from '../Helpers/Fetcher';
import { LocalNotifications } from '@capacitor/local-notifications';

class Settings extends React.Component { 
  
  constructor(props) {
    super(props);
    //default state
    this.state = {
      noti_push_checked:'on',
      noti_cal_checked:'on',
    }
    getLocalStorageItem('fmt_receivePushNotification').then((res) => {
        if(res !== null) this.setState({noti_push_checked:res});
        else this.setState({noti_push_checked:'on'});
    });
    
    getLocalStorageItem('fmt_receiveCalendarNotification').then((res) => {
        if(res !== null) this.setState({noti_cal_checked:res});
        else this.setState({noti_cal_checked:'on'});
    });

  }
  componentDidMount = () => {
    //Get server side user settings
    this.fetchSettings();
  }
  fetchSettings = () => {

    new Fetcher().callApi({
      api_path:'/helpers/get_user_meta',
      api_data:{},
      method:'POST',
    }).then((r) => {
      if(r.status === 'success'){
        this.setState({
          noti_push_checked: (r.results.fmt_receivePushNotification === undefined?'on':r.results.fmt_receivePushNotification), 
          noti_cal_checked: (r.results.fmt_receiveCalendarNotification === undefined?'on':r.results.fmt_receiveCalendarNotification), 
          noti_shareinfo_checked: (r.results.fmt_shareInfo === undefined?'on':r.results.fmt_shareInfo),
        });
      }
      else {
        this.setState({
          noti_push_checked:'on',
          noti_cal_checked:'on',
          noti_shareinfo_checked:'on',
        });
      }
    });

  }
  toggleChange = (e) => {
    const ssscope = e.target.value;
    let noti_push_checked = this.state.noti_push_checked;
    let noti_cal_checked = this.state.noti_cal_checked;
    let noti_shareinfo_checked = this.state.noti_shareinfo_checked;
    
    if(ssscope === 'fmt_receivePushNotification') {
      if(this.state.noti_push_checked === 'on') {
        noti_push_checked = 'off'
        setLocalStorageItem(ssscope,'off')
      }
      else {
        noti_push_checked = 'on'
        setLocalStorageItem(ssscope,'on')
      }
    }
    if(ssscope === 'fmt_receiveCalendarNotification') {
      if(this.state.noti_cal_checked === 'on'){
        noti_cal_checked = 'off'
        setLocalStorageItem(ssscope,'off')
        //remove all calendar notification
        LocalNotifications.getPending().then( res => {
          LocalNotifications.cancel(res);
        }, err => {
          console.log(err);
        })

      }
      else  {
        noti_cal_checked = 'on'
        setLocalStorageItem(ssscope,'on')
      }
    }
    
    if(ssscope === 'fmt_shareInfo') {
      if(this.state.noti_shareinfo_checked === 'on') {
        noti_shareinfo_checked = 'off'
        setLocalStorageItem(ssscope,'off')
      }
      else {
        noti_shareinfo_checked = 'on'
        setLocalStorageItem(ssscope,'on')
      }
    }
    
    this.setState({
      noti_push_checked: noti_push_checked,
      noti_cal_checked: noti_cal_checked,
      noti_shareinfo_checked: noti_shareinfo_checked,      
    }, () => {
        //Update DB
        new Fetcher().callApi({
          api_path:'/helpers/update_user_meta',
          api_data:{
            metas: {
              fmt_receivePushNotification:this.state.noti_push_checked,
              fmt_receiveCalendarNotification:this.state.noti_cal_checked,
              fmt_shareInfo:this.state.noti_shareinfo_checked,
            }
          },
          method:'POST',
        }).then((r) => {
          if(r.status === 'success'){

          }
          else {

          }
        });
    });
  }
  render(){
    return (
        <div id="fmt_settings" className="container general_spacing h-100">   
                <Header Title="Paramètres" rightCta={{showDisconnect:true}} />
                <div className="row h-100">
                    <div className="col-12">
                        <h2 className="my-4">Notifications</h2>
                        <div className="form-check form-switch mb-3">
                            <label className="form-check-label" htmlFor="fmt_receivePushNotification">Importantes (Badges, Plénière, etc.)</label>
                            <input className="form-check-input" value="fmt_receivePushNotification" type="checkbox" id="fmt_receivePushNotification" onChange={(e) => this.toggleChange(e)} checked={this.state.noti_push_checked === 'on'? true : false}  />
                        </div>

                        <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="fmt_receiveCalendarNotification">Calendrier (Alertes RDV)</label>
                            <input className="form-check-input" value="fmt_receiveCalendarNotification" type="checkbox" id="fmt_receiveCalendarNotification" onChange={(e) => this.toggleChange(e)} checked={this.state.noti_cal_checked === 'on'? true : false}  />
                        </div>
                        <br />
                        <hr />
                        <h2 className="my-4">Vie privée</h2>
                        <div className="form-check form-switch mb-3">
                            <label className="form-check-label" htmlFor="fmt_shareInfo">Partager mon numéro de téléphone et email sur ma fiche</label>
                            <input className="form-check-input" value="fmt_shareInfo" type="checkbox" id="fmt_shareInfo" onChange={(e) => this.toggleChange(e)} checked={this.state.noti_shareinfo_checked === 'on'? true : false}  />
                        </div>
                        
                        <div className="button_nav">
                            <Link to={"/change_pwd"} state={{source: "settings"}} className="link">Changer mon mot de passe</Link>
                          </div>
                          

                    </div>
              </div>

      </div>
    );
  }
};

export default Settings;