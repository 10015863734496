import React from 'react';

import Fetcher from '../Helpers/Fetcher';
import {showToast} from '../Helpers/Toast';
import vote1 from '../Assets/img/vote_1.png';
import vote2 from '../Assets/img/vote_2.png';
import vote3 from '../Assets/img/vote_3.png';
import vote4 from '../Assets/img/vote_4.png';
import {CgClose} from 'react-icons/cg';


class ModalFormationVote extends React.Component {
    constructor(props) {  
        super(props);
        this.state = { 
            error: false,
            isLoaded: false,
            formation:[],
            toastState: false,
            toastMessage: 'Message',
            activeClassName: ''
        };
        
    }
    
    componentDidUpdate(previousProps, previousState){;
        if (previousProps.id_formation !== this.props.id_formation) {
            //const uid = this.props.match.params.uid;
            this.fetchFormationApiGet();
        }
    }
    fetchFormationApiGet(){
        new Fetcher().callApi({
            api_path:'/formation/get',
            api_data:{"id_formation": this.props.id_formation},
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
               
            this.setState({
                isLoaded: true,
                formation: r.results,
                activeClassName: 'active_'+r.results.formation_score
            });
            }
            else {
                this.setState({isLoaded: true,error:true})
            }
        });
    } 
    voteFormation(score ){
        this.setState({activeClassName: 'active_'+score});
        //push to server
        
        new Fetcher().callApi({
            api_path:'/formation/post_vote',
            api_data:{
                "id_formation": this.props.id_formation,
                "score": score
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                showToast('Merci pour votre vote');
            }
            else {
                this.setState({isLoaded: true,error:true})
            }
        });
    }
    render() {   
        var curdate = new Date();
        var date = new Date(this.state.formation.end_date);

        return (  
            
            <div id="fmt_modalformationvote" className="sliding-bottom-modal sliding-bottom-close" tabIndex="-1" role="dialog">
                <div className="modal_header mt-4">
                    <h5 className="modal-title">Vote formation</h5>
                    <button type="button" className="close_btn" data-dismiss="modal" aria-label="Close" onClick={() => this.props.closeModal()}><CgClose /></button>
                </div>                
                
                <div className="modal_body">

                
                    <div className="row">
                        <div id="formation_vote" className={'col-12 '+this.state.activeClassName+' text-center'}>

                            <h1>{this.state.formation.libelle_formation}</h1>
                            <h2>par {this.state.formation.first_name} {this.state.formation.last_name}</h2>
                            <br />
                            {date < curdate || process.env.REACT_APP_ENV === 'developpement' ? (
                                <>
                                    <img src={vote1} onClick={() => this.voteFormation(1)} className="vote_1" alt="1/5" /><br />
                                    <img src={vote2} onClick={() => this.voteFormation(2)} className="vote_2" alt="3/5" /><br />
                                    <img src={vote3} onClick={() => this.voteFormation(3)} className="vote_3" alt="4/5" /><br />
                                    <img src={vote4} onClick={() => this.voteFormation(4)} className="vote_4" alt="5/5" /><br />
                                </>
                            ) :  (
                                <p>La formation doit être passée pour pouvoir voter</p>
                            )}
                            
                        </div>
                    </div>
                </div>
            
            </div>

        )
    }
}
export default ModalFormationVote