import React from 'react';

import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import{FaEnvelope,FaPhoneAlt} from 'react-icons/fa';
import {showToast} from '../Helpers/Toast';
import Fetcher from '../Helpers/Fetcher';
import { Dialog } from '@capacitor/dialog';

class ContactCard extends React.Component{
    constructor(props) {  
        super(props);
        this.state = {
            note: props.item.contact_note
        }  
    }
   
    genAvatar(fn, ln){
      return fn.charAt(0)+ln.charAt(0);
    }  
    stringToColour = (str,opacity) => {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var j = 0; j < 3; j++) {
        var value = (hash >> (j * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
        }

        var hex = colour.replace('#','');
        var r = parseInt(hex.substring(0,2), 16);
        var g = parseInt(hex.substring(2,4), 16);
        var b = parseInt(hex.substring(4,6), 16);

        var result = 'rgba('+r+','+g+','+b+','+opacity/100+')';

        return {backgroundColor:result};
    }
    expandCard = (e,data) => {
        /*console.log(e);
        console.log(e.currentTarget.parentNode.parentNode);*/
        const ctnr = e.currentTarget.parentNode.parentNode;
        const x = document.querySelectorAll(".conCard"); 

        if(ctnr.classList.contains('closed')){ 
            for (let i = 0; i < x.length; i++) {
                x[i].classList.add('closed')
            }
            ctnr.classList.remove('closed');
        }
        else {
            ctnr.classList.add('closed');
        }        
    }
    handleChange(event) {
        this.setState({note:event.target.value}); 
    }
    saveNote(uid_contact){
        new Fetcher().callApi({
            api_path:'/contact/save_note',
            api_data:{  
                "uid_contact": uid_contact, 
                "note":this.state.note
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                showToast('La note a bien été sauvegardée');
            }
            else {
                showToast('Une erreur est survenue veuillez réessayer ultérieurement');
            }
        });

    }
    archiveCard(uid_contact){
        const el = document.getElementById('con_'+uid_contact) || null;
        if(el !== null) {
            el.style.display = 'none';
            new Fetcher().callApi({
                api_path:'/contact/archive_contact',
                api_data:{
                    "uid_contact": uid_contact,
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('La fiche a bien été supprimée');
                }
                else {
                    showToast('Une erreur est survenue veuillez réessayer ultérieurement');
                }
            });

        }
    }
    createActionSheet = async (uid_contact) =>{
        const result = await ActionSheet.showActions({
            title: 'Options Contact',
            message: 'Choisissez une option',
            options: [
            {
                title: 'Partager ma fiche à ce contact',
            },
            {
                title: 'Supprimer',
                style: ActionSheetButtonStyle.Destructive,
            },
            {
                title: 'Annuler',
                style: ActionSheetButtonStyle.Cancel,
            },
            ],
        });
        if(result.index === 1) this.archiveCard(uid_contact);
        if(result.index === 0) this.showPrompt(uid_contact);
         
    }
    showPrompt = async (uid_contact) => {
        const { value, cancelled } = await Dialog.prompt({
            title: 'Note',
            message: `Ajoutez une note`,
        });
        if(cancelled === false){
            new Fetcher().callApi({
                api_path:'/contact/share_contact',
                api_data:{  
                    "uid_contact": uid_contact, 
                    "shared_note":value
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('Votre fiche a bien été partagée');
                }
                else {
                    showToast('Une erreur est survenue veuillez réessayer ultérieurement');
                }
            });
        }
    }
    render(){
        return ( 
            <div id={"con_"+this.props.item.uid_user} key={this.props.item.uid_user} className="conCard closed" >
                <div className="row align-items-center">
                    <div className="col-2 ps-1" onClick={(e) => this.expandCard(e, this.props.item)}>
                        <div className="conAvatar uppercase" style={this.stringToColour(this.props.item.contact_first_name,50)}>{this.genAvatar(this.props.item.contact_first_name, this.props.item.contact_last_name)}</div>
                    </div>
                    <div className="col-7 text-start" onClick={(e) => this.expandCard(e, this.props.item)}>  
                        <div className="contact_denomination">
                            {this.props.item.contact_first_name}&nbsp;<span className="uppercase">{this.props.item.contact_last_name}</span>
                        </div>
                        {this.props.item.contact_company} <br />
                        <small>{this.props.item.name_event}</small>                        
                    </div>
                    <div className="col-3">
                        {this.props.item.contact_optin === "1" ? (
                        <div>
                            <a href={"mailto:"+this.props.item.contact_email} className="btn_contact"><FaEnvelope /></a> 
                            <a href={"tel:"+this.props.item.contact_phone} className="btn_contact"><FaPhoneAlt /></a>
                        </div>
                        ) : ( <></>)}
                    </div>
                </div>
                <div className="row expand_block ion-align-items-center">
                    <div className="col-12">
                        {
                            (this.props.item.shared_note !== null && this.props.item.shared_note !== '') ? (
                               <p className="my-2">
                                Note partagée : <br />
                                {this.props.item.shared_note}
                                </p>
                            ) : (<></>)
                        }
                        <div className="my-3">
                            <label>Ajouter une note personnelle : </label>
                            <textarea className="form-control" value={this.state.note} onChange={(e) => {this.handleChange(e)}}></textarea>
                        </div>

                        <div className="row">
                            <div className="col-7">
                                <button className="btn btn-secondary" onClick={() => this.createActionSheet(this.props.item.uid_user)}>Plus d'options...</button>    
                            </div>  
                            <div className="col-4">
                                <button className="btn btn-primary" onClick={() => this.saveNote(this.props.item.uid_user)}>Enregistrer</button>  
                            </div>     
                        </div>               
                 
                    </div>     
                </div>
                
            </div>
        )
    }
};

export default ContactCard;