import { Storage } from '@capacitor/storage';

export const setLocalStorageItem = async (k,v)  => { 
    await Storage.set({key:k,value:v});
}
export const getLocalStorageItem = async (k)  => { 
    const { value } = await Storage.get({key:k});
    return value;
}

export const deleteLocalStorageItem = async (k)  => {
    await Storage.remove({key:k});
}
/*export const getLocalStorageItem = (k) => {
    return localStorage.getItem(k);
}*/
/*export const setLocalStorageItem = (k,i) => {
    return localStorage.setItem(k,i);
}*/