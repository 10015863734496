import React from 'react';

import Header from '../Components/Header';
import ModalFormationVote from '../Components/ModalFormationVote';
import Fetcher from '../Helpers/Fetcher';
import { Dialog } from '@capacitor/dialog';
import {FaRegClock,FaMapMarkedAlt} from 'react-icons/fa';

class FormationsVote extends React.Component{
  constructor(props) {
    super(props);
    this.state = { 
      error: false,
      isLoaded: false,
      formations:[],
      showModal: false,
      id_formation: 0
    };
  } 
  componentDidMount = () => {
    this.fetchFormationApi();
  }
  fetchFormationApi(){
    new Fetcher().callApi({
        api_path:'/formation/list_adh_formation',
        api_data:{},
        method:'POST',
    }).then((r) => {
        if(r.status === 'success'){
            this.setState({
                isLoaded: true,
                formations: r.results
              });
        }
        else {
            this.setState({isLoaded: true,error:true})
        }
    });
  }  
  render(){
    if(this.state.isLoaded){
      if(!this.state.formations.length){
        return (
            <div id="fmt_formationsvote" className="container general_spacing h-100 bg_lightblue">    
                <Header Title="Vote formations" />
                <div className="row">  
                <div className="col text-center">Vous n'avez pas fait de formation</div>
                </div>  
            </div>
        )
      }
      else return this.renderFormation()
    }
    else{
      return (       
        <div id="fmt_formationsvote" className="container general_spacing h-100 bg_lightblue">   
            <Header Title="Vote formations" />
            <div className="row">  
                <div className="col text-center">Chargement</div>
            </div>  
        </div>
        );
    }
    
  }
  /*openVote(id_formation : number){
    console.log(id_formation);
    let mc = modalController.create({
        component: ModalFormationVote, 
        componentProps:{'id_formation':id_formation},
        swipeToClose:true
    }).then(modalC => {
        modalC.present(); 
    });

  }*/
  
    showModal = (cal_item) => {
        var curdate = new Date( Date.now() - 1000 * (60 * 10) );
        var date = new Date(cal_item.cal_end_date);
        if(curdate >= date){                     
            document.body.classList.add('noscroll');
            const acm = document.getElementById('fmt_modalformationvote');
            const acm_title = acm.getElementsByClassName("modal_header")[0];
            const acm_content = acm.getElementsByClassName("modal_body")[0];
            this.setState({
                id_formation:cal_item.id_formation,
                acm_title_height : acm_title.offsetHeight,
            },() => {
                acm_content.style.height = window.innerHeight-this.state.acm_title_height+"px";
                acm.classList.remove('sliding-bottom-close');

            })
        }
        else this.showAlert('Le vote formation est ouvert 10 minutes avant la fin de la formation');
    }
    showAlert = async(message) => {
      await Dialog.alert({
        title: 'Attention',
        message: message,
      });
    }
    closeModal = () =>{  
        const acm = document.getElementById('fmt_modalformationvote');
        acm.classList.add('sliding-bottom-close');
        document.body.classList.remove('noscroll');
        this.fetchFormationApi();
    }

    prettyDay(time){
        var date = new Date(time);
        return date.toLocaleDateString(navigator.language, {weekday: 'short'});
    }
    prettyDate2(time){
        var date = new Date(time);
        return date.toLocaleTimeString(navigator.language, {hour: '2-digit',minute:'2-digit'});
    }
  renderFormation(){
    return (
        <div id="fmt_formationsvote" className="container general_spacing h-100 bg_lightblue">        
            <Header Title="Vote formations" />
            <div key="ctnr_ccc" id="calCardsCont">
                
                {this.state.formations.map((item,key) => {
                    return (
                        <div key={"ccc_"+key} id={"ccc_"+key} className="calCards">
                            {item.cal_items.map((cal_item, cal_key) => {
                                return (
                                    <div key={"cc_"+cal_key} id={"cc_"+cal_key} className="calCard  mb-3" onClick={(e) => this.showModal(cal_item)}>  
                                        <div className="row align-items-center">
                                            <div className="col-10">
                                                <div className="calCardBloc">
                                                <div className="calCardTitle">
                                                    <b>{cal_item.cal_title}</b> <br />
                                                    <div>
                                                        {cal_item.cal_description} 
                                                        <br />
                                                        {cal_item.attendee_company}
                                                    </div>

                                                    <span className="calCardLocation"><FaRegClock /> 
                                                        {this.prettyDay(cal_item.cal_start_date)} : {this.prettyDate2(cal_item.cal_start_date)} - {this.prettyDate2(cal_item.cal_end_date)}
                                                    </span> 
                                                    {cal_item.cal_location !== '' ?  (
                                                        <span className="calCardLocation">
                                                        <FaMapMarkedAlt />   
                                                        {cal_item.cal_location}
                                                        </span>
                                                        ) :(<></>)
                                                    }
                                                </div>
                                                
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                {
                                                    cal_item.formation_score !== null ? ( <span className={"vote_score score_"+cal_item.formation_score}></span>):<span className="circle_btn">Votez</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                )
            })}
      </div>
      <ModalFormationVote id_formation={this.state.id_formation} closeModal={() => this.closeModal()} />
      </div>
    );  
  }

};

export default FormationsVote;
