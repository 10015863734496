import React from 'react';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

class Fetcher extends React.Component {
    async getTokens(key) {
        return await SecureStoragePlugin.get({ key });
    }
    callApi = async (d, new_token = '') => {  
        //get tkn
        let tkn; 
        if(new_token === '') tkn  = await this.getTokens('fmt_tokens_token').then((r) => { return r.value}).catch(error => {window.location.href = '/login';});
        else tkn = new_token;
        //call api
        const url =  process.env.REACT_APP_API_URL + d.api_path;
        const res = await fetch(url, {
                method: 'POST',
                headers: {
                    "Authorization": tkn,
                    "Content-Type": "application/json", 
                },
                body: JSON.stringify(d.api_data)
            })
            .then((r) => r.json())
            .then((result) => {
                if(result.status === 'token_error') window.location.href = '/login';
                else if(result.status === 'token_expired') return 'get_new_token';
                else return result
            });
        //if new tkn requested then call same func again
        if(res === 'get_new_token'){
            const new_tkn = await this.refreshToken(d).then((rr) => { return rr});
            if(!new_tkn) window.location.href = '/login';
            const again = await this.callApi(d,new_tkn)
            return again;
        }
        else return res;
    }

    refreshToken = async (d) => {
        const bku  =  await this.getTokens('fmt_tokens_refresh_token').then((r) => { return r.value}).catch(error => {window.location.href = '/login';});
        const nt = await fetch(process.env.REACT_APP_API_URL + '/auth/refresh_token', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest"
                },
                body: JSON.stringify({'refresh_token':bku})
              })
              .then((res) => res.json())
              .then((result) => {
                if(result.status === 'error') window.location.href = '/login';
                else if(result.status === 'token_error') window.location.href = '/login';
                else if(result.status === 'success'){
                        SecureStoragePlugin.set({key:"fmt_tokens_token", value:result.results.token});
                        SecureStoragePlugin.set({key:"fmt_tokens_refresh_token", value:result.results.refresh_token});
                        return result.results.token;
                    }
                    else return false;
                },
                (error) => {
                    return false;
                }
        );
        return nt;
    }

}
export default Fetcher;