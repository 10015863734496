import { useNavigate } from 'react-router-dom';
import {BsChevronLeft} from 'react-icons/bs';

 function BackButton() {
  const navigate = useNavigate();

  return (
    <div>
      <button className="back_button" onClick={() => navigate(-1)}><BsChevronLeft /></button>
    </div>
  );
}

export default BackButton;
