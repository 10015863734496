import React from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../Components/Header';
import Fetcher from '../Helpers/Fetcher';
import { Dialog } from '@capacitor/dialog';
import {prettyDay,prettyDate2} from '../Helpers/Global';
import {FaRegClock,FaMapMarkerAlt} from 'react-icons/fa';

class FormationsScan  extends React.Component{
  constructor(props) {
    super(props);
    this.state = { 
      error: false,
      isLoaded: false,
      formations: {},
      showModal: false,
      id_formation: 0,
      id_horaire:0,
      title_formation:'', 
      start_formation:'', 
      end_formation:'', 
      formation_start_formation:''
    }; 

    this.fetchFormationApi();
    
  } 
  fetchFormationApi(){
    new Fetcher().callApi({
        api_path:'/formation/list_fmteur_formation',
        api_data:{},
        method:'POST',
    }).then((r) => {
        if(r.status === 'success'){
            this.setState({
                isLoaded: true,
                formations: r.results
              });
        }
        else {
            this.setState({isLoaded: true,error:true})
        }
    });
  }  
  render(){
    if(this.state.isLoaded){
      if(this.state.formations.length === 0){
        return (
          <div id="fmt_formationsvote" className="container general_spacing h-100 bg_lightblue">    
              <Header Title="Vos formations" />
              <div className="row">  
              <div className="col text-center">Vous n'avez pas de formation</div>
              </div>  
          </div>
        )
      }
      else return (
        this.renderFormation()
      )
    }
    else{
      return (   
        <div id="fmt_formationsvote" className="container general_spacing h-100 bg_lightblue">   
            <Header Title="Vos formations" />
            <div className="row">  
                <div className="col text-center">Chargement</div>
            </div>  
        </div>
        );
    }
    
  }
  goToScanPage = (id_formation,id_horaire, start_date) => {
    var curdate = new Date( Date.now() - 1000 * (60 * 10) );
    var date = new Date(start_date);
    if(curdate >= date){
      this.props.navigation('/formations_scan_camera/'+id_formation+'/'+id_horaire);
    }
    else {
      this.showAlert('Le scan est ouvert 10 minutes avant le début de la formation');
    }      
  }
  showAlert = async(message) => {
    await Dialog.alert({
      title: 'Attention',
      message: message,
    });
  }
  closeModal = () =>{  
      const acm = document.getElementById('fmt_modalformationscan');
      acm.classList.add('sliding-bottom-close');
      document.body.classList.remove('noscroll');
      this.fetchFormationApi();
  }
  renderFormation(){
    return (
      <div id="fmt_formationsvote" className="container general_spacing h-100 bg_lightblue">        
          <Header Title="Vos formations" />
            <div key="ctnr_ccc" id="calCardsCont" className="evMargin">
                
              
            {(() => {
                if(this.state.formations){
                  return (
                      this.state.formations.map((item,key) => {
                          return (
                              <div key={"ccc_"+key} id={"ccc_"+key} className="calCards">
                                  {item.cal_items.map((cal_item, cal_key) => {
                                      return (
                                          <div key={"cc_"+cal_key} id={"cc_"+cal_key} className="calCard  mb-3">  
                                              <div className="row align-items-center">
                                                  <div className="col-10">
                                                      <div className="calCardBloc">
                                                        <div className="calCardTitle">
                                                            <b>{cal_item.cal_title}</b> <br />
                                                            <span className="calCardLocation"><FaRegClock /> 
                                                                {prettyDay(cal_item.cal_start_date)} : {prettyDate2(cal_item.cal_start_date)} - {prettyDate2(cal_item.cal_end_date)}
                                                            </span> 
                                                            {cal_item.cal_location !== '' ?  (
                                                                <span className="calCardLocation">
                                                                <FaMapMarkerAlt />   
                                                                {cal_item.cal_location}
                                                                </span>
                                                                ) :(<></>)
                                                            }
                                                        </div>
                                                        
                                                      </div>
                                                  </div>
                                                  <div className="col-2" onClick={() => this.goToScanPage(cal_item.id_formation,cal_item.id_horaire,cal_item.cal_start_date)}>
                                                      <span className="circle_btn">Scan</span>
                                                  </div>
                                              </div>
                                          </div>
                                      )
                                      })}
                          </div>
                      )
                  })
                )
              }
              else return (
                <p>Vous n'avez aucune formation.</p>
              )
            })()}
            </div>
            

      </div>

    );  
  }

};

export default function(props) {
    const navigation = useNavigate();
    return <FormationsScan {...props} navigation={navigation} />;
}
