import React from 'react';
import Header from '../Components/Header';
import Fetcher from '../Helpers/Fetcher';


class Help extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          message:'',
          formCompleted:false
      };
    }
    
    handleChange(event) {
        this.setState({message:event.target.value}); 
    }
    handleSubmit = () => {
        new Fetcher().callApi({
            api_path:'/helpers/send_help',
            api_data:{  
              "message": this.state.message
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
              this.setState({
                  formCompleted:true
              });
            }
            else {
              //this.setState({formCompleted: true,error:true})
            }
        });
    }
    render() {  
        return (
            <div id="fmt_help" className="container general_spacing h-100 overflow-auto">   
            
                <Header Title="Aide" />
                
              {(() => {
                if(this.state.formCompleted){
                    return (
                        <div className="alert alert-success">Merci, un organisateur va vous contacter.</div>
                    )
                }
                else{
                    return (

                        <div className="row px-1 pt-4"> 
                            <div className="col-12">
                                    <p><b>Un problème technique ?</b> Envoyez un message aux organisateurs avec le formulaire ci-dessous.</p>
                                    <textarea className="form-control" rows={6} value={this.state.message} onChange={(e) => {this.handleChange(e)}}></textarea>
                                    <div className='text-center mt-3'>
                                        <button className='btn btn-primary' onClick={(e) => this.handleSubmit(e)}>Envoyer</button>
                                    </div>
                            </div>
                        </div>
                    )
                }
                })()}
            </div>
        )
    }  
}

export default Help;