import React from 'react';
import { Device } from '@capacitor/device';
import { Camera } from '@capacitor/camera';
import Fetcher from '../Helpers/Fetcher';
import { showToast} from '../Helpers/Toast';

import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';

import Header from '../Components/Header';


class Hotesse extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        error: false,
        isLoaded: false,
        scans:[],
        item : '',
        errorMsg:'',
        formateur: false
      };    
    }
    componentDidMount() {
        this.logDeviceInfo().then((platform) => { 
            if(platform !== 'web') { 
              this.openScan();
            }
            else this.setState({
                error:true,
                errorMsg:'Indisponible sur web'
            })
        });
    }
    componentWillUnmount() {
      /*BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
      document.body.style.background = "";*/
    }
    logDeviceInfo = async () => {
        const info = await Device.getInfo();
        return info.platform;
    }
    openScan = async () => {
      //check perm
      this.checkPerms().then(result => { if (result === true) { 
          
        //css hacks
        document.body.style.background = "transparent";
        BarcodeScanner.hideBackground(); 
        this.startScan();
      }
      else{ alert('Nous avons besoin de votre caméra pour le scan de QR code. Rendez vous dans Réglages de votre téléphone > Recherchez “FORMATECH“ > Activez “App. photo“') } });
        
    }
    startScan = async () => {
      console.log('-- start scan --');
      const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] }); // start scanning and wait for a result
    
      // if the result has content
      if (result.hasContent) {
        if(this.state.previousScan !== result.content){ //check if not same QR code, avoid multiple server calls
          //LOG in DB
          new Fetcher().callApi({
            api_path:'/scan/hotesse',
            api_data:{
              user_code:result.content
            },
            method:'POST',
          }).then((r) => {
              if(r.status === 'success'){
                console.log('-- scans --', r.results);
                console.log('-- nb scans --', r.results.length);
                let hscans = this.state.scans;
                hscans = this.prepend(r.results,hscans);
                console.log(hscans);
                this.setState({
                  isLoaded: true,
                  previousScan:result.content,
                  scans: hscans
                });
              }
              else showToast(r.message);
              this.startScan(); //relaunch scan
          });
        }
        else this.startScan(); //relaunch scan
      }
  }
  prepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }
  checkPerms = async () => {
    const perm = await Camera.checkPermissions();
    if(perm.camera === 'granted'){
      return true
    }
    else {
      const permR = await Camera.requestPermissions({permissions:['camera']});
      
      if(permR.camera === 'granted'){
        return true
      }
      else { 
        return false;
      }
    }
  }
  renderBadge = (c,m) => {
    switch(c) {
        case 'ok':
            return <small><span className="badge rounded-pill bg-success">&nbsp;</span> {m}</small>
        case 'deja_scanne':
            return <small><span className="badge rounded-pill bg-warning text-dark">&nbsp;</span> {m}</small>
        case 'non_inscrit':
            return <small><span className="badge rounded-pill bg-danger">&nbsp;</span> {m}</small>
        default:
            return <small><span className="badge rounded-pill bg-secondary">&nbsp;</span> na</small>
    }
  }
  render(){
      //const { error, isLoaded, items } = this.state;
     // console.log(this.state.error);
      if (this.state.error) {
        return (
            <div id="fmt_scanner" className="container general_spacing h-100">   
                <Header Title="Scan QR code" rightCta={{showDisconnect:true}} />
                <div>
                  <p className="bg_light-red evMargin evPadding">{this.state.errorMsg}</p>
                </div>
            </div>
        )
      }
      else {
        return (
            <div id="fmt_scanner" className="container general_spacing h-100">    
              <Header Title="Scan QR code" rightCta={{showDisconnect:true}} />

              <div>
              <ul id="scans_ctnr">
              {(() => {
                if(this.state.scans.length !== 0){
                  return(
                    this.state.scans.map((u,k) => {
                        return (
                            
                            <li key={k} className={u.code}>
                                <div className="row">
                                    <div className="col-8">
                                        {u.first_name} {u.last_name} ({u.name_company}) <br />
                                        <small>{u.log_date}</small> 
                                    </div>
                                    <div className="col-4">
                                       {this.renderBadge(u.code,u.message)}
                                    </div>
                                </div>  
                            </li>
                          
                        )
                    })
                  )
                }
              })()}
              </ul>

              </div>
          </div>
      );
    }
  } 
};

export default Hotesse;