
import { Toast } from '@capacitor/toast';

export const showToast = async (m = 'Hello')  => {
  await Toast.show({
    text: m,
    duration: 'long'
  });
};
export const showDomError = (m = '')  => {
    
    const error_dom = document.getElementById('error_dom');
    error_dom.classList.remove("d-none");
    error_dom.innerHTML = m
};

