import React from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { FaHome,FaRegCalendarAlt,FaQrcode } from 'react-icons/fa';
import {RiContactsBookLine} from 'react-icons/ri';
import {BsGearFill} from 'react-icons/bs';

import {getLocalStorageItem} from '../Helpers/Storage';

let p = '';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    const location  = useLocation();
    
    return (
      <Component
      history={history}
      location={location}
      {...props}
        />
    );
  };
  
  return Wrapper;
};


class Navigation extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            showDistributeur:'d-none',
            showFormateur:'d-none',
            active:{
                'home':'button-inactive',
                'calendar':'button-inactive',
                'qrscanner':'button-inactive',
                'ticket':'button-inactive',
                'address':'button-inactive',
            },
            path:this.props.location.pathname.substring(1)
        };
    }
    componentDidMount() {  
        getLocalStorageItem('fmt_user_info').then((r)=>JSON.parse(r)).then((res) => {
            if(res !== null){
                if(res.type_company === 'distributeur') this.setState({showDistributeur:'d-block'});
                if(res.formateur === true) this.setState({showFormateur:'d-block'});
            }
        });

        if(this.state.path === '') p = 'home';
        else p = this.state.path;
        this.setState({
            active:{
                [p]:'button-active'
            }
        });
        document.getElementById('root').classList.add(p);
    }
    componentDidUpdate(previousProps, previousState){
        if (previousProps.location.pathname !== this.props.location.pathname) {
            ///Hack for login
            getLocalStorageItem('fmt_user_info').then((r)=>JSON.parse(r)).then((res) => {
                if(res !== null){
                    if(res.type_company === 'distributeur') this.setState({showDistributeur:'d-block'});
                    if(res.formateur === true) this.setState({showFormateur:'d-block'});
                }
            });
            
            const tplps_prev = previousProps.location.pathname.substring(1);
            const tplps = this.props.location.pathname.substring(1);

            if(tplps === '') p = 'home';
            else p = tplps;
            
            this.setState({
                path:tplps,
                active:{
                    [p]:'button-active'
                }
            })
            
            if(tplps_prev !== '')  document.getElementById('root').classList.remove(tplps_prev);
            document.getElementById('root').classList.add(p);
            
        }
        

    }
    goTo = (url) => {
        this.props.history(url);
        this.setState({
            active:{
                [url]:'button-active'
            }
        });
        
    }
    goToSidebar= (url) => {
        this.closeNav();
        this.props.history(url);        
    }
    closeNav = () => {
        document.getElementById("sidebar").style.marginRight = "-250px";
        document.getElementById("sidebar").classList.replace("open", "close");
    }
    hideItemMenu = (type, type_v) => {
      if(type_v === this.state[type]) return 'd-none';
      else return 'd-block';
    }
    render() { 
        return (
            <>
            {this.props.hideMainNav || this.state.path === 'login' || this.state.path === 'force_change_pwd' || this.state.path === 'hotesse' || this.state.path.startsWith('formations_scan_camera/') ?'': (
                <>
                <footer className="footer">
                    <div id="buttonGroup" className="btn-group selectors" role="group" aria-label="Basic example">
                        <button id="home" type="button" className={"btn "+this.state.active['home']} onClick={() => this.goTo('/home')}>
                            <div className="selector-holder">
                                <FaHome />
                            </div>
                        </button>
                        <button id="calendar" type="button" className={"btn "+this.state.active['calendar']}  onClick={() => this.goTo('/calendar')}>
                            <div className="selector-holder">
                                <FaRegCalendarAlt />
                            </div>
                        </button>
                        <button id="alerts" type="button" className={"btn "+this.state.active['qrscanner']}  onClick={() => this.goTo('/qrscanner')}>
                            <div className="selector-holder">
                                <FaQrcode />
                            </div>
                        </button>
                        <button id="contacts" type="button" className={"btn "+this.state.active['contacts']}  onClick={() => this.goTo('/contacts')}>
                            <div className="selector-holder">
                                <RiContactsBookLine />
                            </div>
                        </button>
                        <button id="settings" type="button" className={"btn "+this.state.active['ticket']}  onClick={() => this.goTo('/settings')}>
                            <div className="selector-holder">
                                <BsGearFill />
                            </div>
                        </button>
                    </div>
                </footer>
                {/*<div id="sidebar" className="close">
                    <button className="closebtn btn-flush" onClick={() => this.closeNav()}>&times;</button>
                    <button onClick={() => this.goToSidebar('/quizz')} className={'link '+this.state.showDistributeur}>Quizz</button>
                    <button onClick={() => this.goToSidebar('/formations_scan')} className={'link '+this.state.showFormateur} >Vos formations</button>
                    <button onClick={() => this.goToSidebar('/formations_vote')} className={'link '+this.state.showDistributeur}>Vote formations</button>
                    <button onClick={() => this.goToSidebar('/login')} className="link"><FaSignOutAlt /> Se déconnercter</button>
            </div>*/ }
                </>

                )}
            </>

        )
    }  
}

export default withRouter(Navigation);
