import React from 'react';

import Header from '../Components/Header';
import { FaFilePdf } from 'react-icons/fa';

import {getLocalStorageItem} from '../Helpers/Storage';
import Fetcher from '../Helpers/Fetcher';

class Ticket extends React.Component {

  constructor(props) {  
    super(props);
    this.state = {
      company_user:'',
      lastname_user:'',
      firstname_user:'',
      calIsLoaded:false,
      badges:false,
      planning:false,

    }
  }
  componentWillMount(){
    getLocalStorageItem('fmt_user_info').then((r) => JSON.parse(r)).then((res) => {
      this.setState({
        id_event:res.id_event,
        uid_user:res.uid_user,
        user_code:res.user_code,
        company_user:res.company_user,
        firstname_user:res.firstname_user,
        lastname_user:res.lastname_user
      });
    });
    this.checkExistingPdf();
  }
  checkExistingPdf = () => {  
    new Fetcher().callApi({
        api_path:'/helpers/checkExistingPdf',
        api_data:{},
        method:'POST',
    }).then((r) => {
        if(r.status === 'success'){
          this.setState({
            badges: r.results.badges,
            planning: r.results.planning
          });

        }
        else {
          this.setState({calIsLoaded: true,error:true})
        }
    });
  }

  

  openPdf(t){
    window.open(process.env.REACT_APP_FILE_URL+"/"+t+"/"+this.state.id_event+"/"+this.state.uid_user+".pdf","_system","location=yes,enableViewportScale=yes,hidden=no");
  }
  render(){

    return (
        
      <div  id="fmt_calendar" className="container general_spacing h-100 bg_lightblue">   
        <Header Title="Mon QR Code" /> 
        <div className="row h-100 ion-align-items-center" style={{paddingBottom:'20%'}}>
            <div className="col-1"></div>
            <div className="col-10 text-center">

                <h1>{this.state.firstname_user} {this.state.lastname_user}</h1>
                <h2>{this.state.company_user}</h2>
                <br />
                <img src={process.env.REACT_APP_FILE_URL+"/qr_codes/"+this.state.user_code+".jpg"} alt="QR Code non disponible" />
                
                {(() => {
                if(this.state.badges){
                  return(
                    <>
                      <br /><br />
                      <button className="btn btn-round" onClick={() => this.openPdf('badges')}>
                        <FaFilePdf icon="file-pdf" /> &nbsp;
                        Télécharger votre badge
                      </button>
                    </>
                  )
                }
              })()}
                
              {(() => {
                if(this.state.planning){
                  return(
                    <>
                    <br /><br />
                    <button className="btn btn-round" onClick={() => this.openPdf('planning')}>
                      <FaFilePdf icon="file-pdf" /> &nbsp;
                      Télécharger votre planning
                    </button>
                    </>
                  )
                }
              })()}

               

            </div>
            <div className="col-1"></div>
        </div>
      </div>
    );
  }
};

export default Ticket;
