import React, { Component } from 'react';

import {BrowserRouter as Router,Routes, Route} from "react-router-dom";

import './Scss/App.scss';

import AuthLogin from './Pages/AuthLogin';
import AuthChangePwd from './Pages/AuthChangePwd';

import Home from './Pages/Home';
import HelpRequests from './Pages/HelpRequests';
import Help from './Pages/Help';
import Hotesse from './Pages/Hotesse';
import Calendar from './Pages/Calendar';
import Ticket from './Pages/Ticket';
import QrScanner from './Pages/QrScanner';
import Contacts from './Pages/Contacts';
import Quizz from './Pages/Quizz';
import Settings from './Pages/Settings';
import FormationsVote from './Pages/FormationsVote';
import FormationsScan from './Pages/FormationsScan';
import FormationScanCamera from './Pages/FormationsScanCamera';

import {getLocalStorageItem} from './Helpers/Storage';
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import Fetcher from './Helpers/Fetcher';
import {PushNotifications } from '@capacitor/push-notifications';

import Error404 from './Pages/Error404';

//import PrivateRoute from './Middleware/PrivateRoute';
import RequireAuth from './Middleware/RequireAuth'; 
import Navigation from './Components/Navigation';
import ScrollToTop from './Components/ScrollToTop';

class App extends Component {

	  constructor(props) {
        super(props);  
        this.state = {
            hotesse:false,
        }
    }

    componentDidMount(){  
       
       getLocalStorageItem('fmt_user_info').then((r)=>JSON.parse(r)).then((res) => {
           if(res !== null){
               if(res.type_company === 'hotesse') this.setState({hotesse:true});
           }
       });

      //Push Notification listeners
      this.logDeviceInfo().then((platform) => { 
        //add class to root
        document.getElementById('root').classList.add('body_'+platform); //_ios'); 
        //skip notification on web
        if(platform !== 'web'){
              this.registerNotificationListeners();   
        }
      }) 
      //Network
      Network.addListener('networkStatusChange', status => {
          const erroroffline = document.getElementById('erroroffline');
          if(!status.connected) erroroffline.classList.remove('d-none')
          else erroroffline.classList.add('d-none')
      });

    }
    logDeviceInfo = async () => {
        const info = await Device.getInfo();
        return info.platform;
    }
    registerNotificationListeners = () => {
        //TODO Check if token is still valid

        //init register
        PushNotifications.register();

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification) => {
                this.openPageviaNotification(notification);
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification) => {
                this.openPageviaNotification(notification.notification);
            }
        );
    }
    openPageviaNotification(notification){
        //mark as read
        if(notification.data.uid_notification !== undefined && window.location.pathname !== '/login'){
            console.log('openPageviaNotification > go');
            new Fetcher().callApi({
                api_path:'/helpers/notification_mark_read/',
                api_data:{
                    "uid_notification": notification.data.uid_notification
                },
                method:'POST',
            }).then((r) => {
                //Go to the URL
                if(notification.data.url !== undefined){
                    window.location.href = notification.data.url;
                }
            });

        }
        else if(notification.data.url !== undefined){
            window.location.href = notification.data.url;
        }
        else {}
    }  
    render() {
      return (
        <div id="app" className="App h-100">
          <div id="erroroffline" className="alert alert-danger d-none">Il semblerait que vous soyez hors ligne. Vous devez être connecté(e) pour utiliser notre application.</div>
          <Router>
              <ScrollToTop />
              <Routes>
                <Route exact path="/login" element={<AuthLogin />}  /> 
                
                <Route element={<RequireAuth />}>
                  <Route exact path="/" element={(this.state.hotesse?<Hotesse hideSidebar={true} />:<Home />)} />
                  <Route exact path="/force_change_pwd" element={<AuthChangePwd />} />
                  <Route exact path="/change_pwd" element={<AuthChangePwd showHeader={true} />} />
                  <Route exact path="/home" element={<Home />} />
                  <Route exact path="/help" element={<Help />} />
                  <Route path="/help_requests">
                    <Route path="" element={<HelpRequests />} />
                    <Route path="/help_requests/:uid_notification" element={<HelpRequests />} />
                  </Route>
                  <Route exact path="/hotesse" element={<Hotesse hideSidebar={true} />} />
                  <Route exact path="/calendar" element={<Calendar />} />
                  <Route exact path="/calendar/free_formation" element={<Calendar type="free_formation" />} />
                  <Route exact path="/ticket" element={<Ticket />} />
                  <Route exact path="/qrscanner" element={<QrScanner />} />
                  <Route exact path="/contacts" element={<Contacts />} />
                  <Route exact path="/quizz" element={<Quizz />} />
                  <Route exact path="/formations_vote" element={<FormationsVote />} />
                  <Route exact path="/formations_scan" element={<FormationsScan />} />
                  <Route exact path="/formations_scan_camera/:id_formation/:id_horaire" element={<FormationScanCamera />} />
                  <Route exact path="/settings" element={<Settings />} />
                  
                </Route>
                <Route path="*"  render={props => <Error404 {...props} />} />
              </Routes>

              <Navigation hideMainNav={(this.state.hotesse?true:false)}  />

          </Router>
      </div>
      )
    }

}

export default App;