import React from 'react';

class HpHighlightsCards extends React.Component {
    render(){
        return (         
            <div className="evCard bg_blue mt-3">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <a href={this.props.item.link}>
                                <span className="title">{this.props.item.title}</span>
                                {this.props.item.description}
                            </a>                  
                        </div>
                    </div>
            </div>
        )
    }
};

export default HpHighlightsCards;