import React from 'react';
import {useParams,useNavigate } from "react-router-dom";

import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { Camera } from '@capacitor/camera';
import { Device } from '@capacitor/device';

import {getLocalStorageItem} from '../Helpers/Storage';
import Fetcher from '../Helpers/Fetcher';
import { showToast} from '../Helpers/Toast';
import {prettyDate2} from '../Helpers/Global';

import Header from '../Components/Header';

class FormationsScanCamera extends React.Component {
    constructor(props) { 
        super(props);
        this.state = { 
            error: false,
            isLoaded: false,
            toastState: false,
            toastMessage: 'Message',
            errorMsg:'',
            formation:{
              info:{},
              participants:{}
            },
        };    
    }
    logDeviceInfo = async () => {
        const info = await Device.getInfo();
        return info.platform;
    }
    componentDidMount() {
      this.fetchContent();

      getLocalStorageItem('fmt_user_info').then((r)=>JSON.parse(r)).then((res) => {
          if(res !== null){
              if(res.formateur === true) this.setState({formateur:true});
          }
      });

      this.logDeviceInfo().then((platform) => { 
          if(platform !== 'web') {}
          else this.setState({
              error:true,
              errorMsg:'Scan indisponible sur le web'
          })
      });
        
    }
    componentWillUnmount() {
      /*console.log(BarcodeScanner);
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();*/
      document.body.style.background = "";
    }

    fetchContent = () => {
        new Fetcher().callApi({
          api_path:'/formation/get_formation_for_fmt',
          api_data:{
            "id_formation": this.props.params.id_formation,
            "id_horaire": this.props.params.id_horaire
          },
          method:'POST',
      }).then((r) => {
          if(r.status === 'success'){
             
            this.setState({
                isLoaded: true,
                formation: r.results
            });
          
          }
          else {
              this.setState({isLoaded: true,error:true})
          }
      });
    }

    openScan = async () => {
        //check perm
        this.checkPerms().then(result => { if (result === true) { 
            console.log('-- open scan --');
          this.startScan();
        }
        else{ alert('Nous avons besoin de votre caméra pour le scan de QR code. Rendez vous dans Réglages de votre téléphone > Recherchez “FORMATECH“ > Activez “App. photo“') } });
          
      }
      startScan = async () => {
        console.log('-- start scan --');
        //css hacks
        document.body.style.background = "transparent";
        document.getElementById('list_participants').classList.add('d-none');
  
        BarcodeScanner.hideBackground(); 
  
        const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] }); // start scanning and wait for a result
      
        console.log('-- start scan after res --');
        console.log(result);
        // if the result has content
        if (result.hasContent) {
          console.log(result.content); // log the raw scanned content
  
          //LOG in DB
          new Fetcher().callApi({
            api_path:'/scan/formation/'+this.props.params.id_formation+'/'+this.props.params.id_horaire,
            api_data:{
              user_code:result.content
            },
            method:'POST',
          }).then((r) => {
            console.log('-- res --', r);
              if(r.status === 'success'){
                this.setState({
                  isLoaded: true,
                  code: r.code,
                  message: r.message
                });
              }
              else showToast("Une erreur est survenue");

              this.closeScan();

          });
        }      
    }
    closeScan = () => {
      BarcodeScanner.showBackground(); 
      document.body.style.background = "";
      this.fetchContent();
      document.getElementById('list_participants').classList.remove('d-none');

    }
    checkPerms = async () => {
      
      const perm = await Camera.checkPermissions();
      console.log('-- perm --',perm);
      if(perm.camera === 'granted'){
        return true
      }
      else {
        const permR = await Camera.requestPermissions({permissions:['camera']});
        
        if(permR.camera === 'granted'){
          console.log('-- perm --',permR);
          return true
        }
        else { 
          console.log('-- perm --',permR);
          return false;
        }
      }
  
    }
  
    

    render() {   
        return (  
            
          <div id="fmt_scanner" className="container general_spacing h-100">    
            <Header Title={"Scan formation : "+this.state.formation.info.libelle_formation} subTitle={'Session : '+prettyDate2(this.state.formation.info.start_date) + ' - ' +prettyDate2(this.state.formation.info.end_date)} goBack={true} />

            <div>              
              
                    {(() => {
                      if (this.state.error) {
                        return (
                            <p className="bg_light-red evMargin evPadding">{this.state.errorMsg}</p>
                        )
                      }
                      else { 
                          return (
                            <>
                              <div className="d-grid my-3">
                                <button className="btn btn-primary btn-block" onClick={() => this.openScan()}>Nouveau scan</button>
                              </div>
                              <div className="d-grid my-3">
                                <button className="btn btn-secondary btn-block" onClick={() => this.closeScan()}>Fermer le scan</button>
                              </div>
                              </>
                          )
                      }
                    })()}
                    
                    {(() => {
                      if (this.state.formation) {
                          return (
                                  <div id="list_participants" className="container h-100">
                                    <div className="row">
                                          <ul class="list-group">
                                          {Object.values(this.state.formation.participants).map((p, pk) => {
                                            return (
                                                <li key={"cc_"+pk} id={"cc_"+pk}>
                                                      <li class="list-group-item d-flex justify-content-between align-items-center">
                                                        {p.first_name} {p.last_name}
                                                        <span class={"badge bg-"+(p.prevu?'primary':'secondary')+" rounded-pill"}>{(p.prevu?(p.formation_checking !== null?'Scanner':''):(p.formation_checking !== null?'Scanner (non prévu)':''))}</span>
                                                      </li>
                                                </li>
                                            )
                                            })}
                                            </ul>
                                        </div>
                                </div>
                          )
                      }
                    else {
                      return (
                        <p>Aucun participants</p>
                      )
                    }
                  })()}

                </div>
            </div>

        )
        
    }
}

export default function(props) {
    let navigation = useNavigate();
    let params = useParams();
    return <FormationsScanCamera {...props} navigation={navigation} params={params} />;
}
