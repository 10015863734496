import React from 'react';
import logo from '../Assets/img/logo.png'
import {BiQrScan,BiHelpCircle} from 'react-icons/bi'
import {FaVoteYea} from 'react-icons/fa'
import {FaSignOutAlt} from 'react-icons/fa';
import {BsChevronLeft} from 'react-icons/bs';

import {Link} from "react-router-dom";
import BackButton from './BackButton'

const Header = (props) => {
    return ( 
        <div id="fmt_header" className="d-flex bd-highlight mb-4 align-items-center">
            
            {(() => {
                if(props.goBack){
                  return(
                    <BackButton />
                  )
                }
                else if(props.forceBackUrl){
                    //TODO fix design issue
                  return(
                    <Link to={props.forceBackUrl} className="back_button d-block"><BsChevronLeft /></Link>
                  )
                }
                else {
                    return(
                        <div className=""><img src={logo} alt="logo" className="ionToolbarLogo" /></div>
                    )
                }
            })()}
           
            <div className="ps-4 title">
                {props.Title}
                
            
            {(() => {
                if(props.subTitle){
                  return(
                      <p className="fw-normal">{props.subTitle}</p>
                  )
                }
            })()}
           
            </div>
            
            
            {(() => {
                if(props.goBack || props.hideSidebar){
                  return(
                    <></>
                  )
                }
                else { 
                    if(props.rightCta !== undefined){
                        if(props.rightCta.showFormateur){
                            return(
                                <Link to="/formations_scan" className="btn btn-ctaRight ms-auto"><BiQrScan /> Vos formations</Link>
                            )
                        }
                        
                        if(props.rightCta.showDistributeur){
                            return(
                                <Link  to="/formations_vote" className="btn btn-ctaRight ms-auto"><FaVoteYea /> Vote formations</Link>
                            )
                        }
                        
                        if(props.rightCta.showDisconnect){
                            return(
                                <Link  to="/login" className="btn btn-ctaRight ms-auto"><FaSignOutAlt />  Se déconnecter</Link>
                            )
                        }
                        if(props.rightCta.showHelp){
                            return(
                                <Link  to="/help" className="btn btn-ctaRight ms-auto"><BiHelpCircle />  Besoin d'aide</Link>
                            )
                        }
                    }
                }
              })()}
        </div>                   
    )

}
export default Header