import React from 'react';
import { useNavigate,useLocation} from 'react-router-dom';
import Header from '../Components/Header';
import { HiLockClosed } from 'react-icons/hi';
import { showToast,showDomError } from '../Helpers/Toast';
import Fetcher from '../Helpers/Fetcher';
import {validateField,validateForm, errorClass} from '../Helpers/Formvalidation'

class AuthChangePwd extends React.Component {  
    constructor(props) {
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.state = {
            password_confirm: '',
            password: '',
            //new
            formFields: {password_confirm: '', password: ''},
            formErrors: {password_confirm: '', password: ''},

        };   
    }
    componentDidMount(){
        //console.log(this.props.location.state);
    }
    handleChange(e) {
        
        const name = e.target.name;
        const type = e.target.type;
        const value = type === "checkbox" ? e.target.checked : e.target.value;   
        this.setState({
            formFields:{
                ...this.state.formFields,
                [name]: value,
            }
        }, () => {
            //Errors
            const err = validateField(e.target);
            this.setState({
                formErrors : {
                    ...this.state.formErrors,
                    [name]:(err.fieldValid?'success':'error')
                }
            });

        });

    }
    handleSubmit() {
        //Chech data
        if(validateForm(this.state.formErrors) === 'success'){
            new Fetcher().callApi({
                api_path:'/auth/change_pwd',
                api_data:{
                    "formFields" : this.state.formFields
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('Bien enregistré');
                    const that = this;
                    setTimeout(function() { 
                        that.props.navigation('/home');
                     }, 1000);
                }
                else showDomError(r.message);
            });
        }
        else showToast('Votre formulaire comporte des erreurs');

    }
    render() {
        if(this.props.showHeader){
            return (
                <div id="fmt_login_setting" className="container general_spacing h-100">
                    <Header Title="Mot de passe" goBack={true} />
                    <div id="fmt_login"> 
                    
    
                        <main className="form-signin">
                            
                                {(() => {
                                    if(this.props.location.state.source !== undefined && this.props.location.state.source !== 'settings'){
                                        return(
                                            <div class="alert alert-icon alert-warning" role="alert">
                                                <i class="fas fa-exclamation-triangle"></i> Ceci est votre première connexion, merci de bien vouloir changer votre mot de passe.
                                            </div>
                                        )
                                    }
                                })()}
                                
                                <div id="error_dom" className="alert alert-danger text-start d-none"></div>
    
                                <div className="form-floating mt-4">
                                    <input type="password" className={"form-control "+errorClass(this.state.formErrors.password)} id="password" name="password" placeholder="Mot de passe" value={this.state.formFields.password}  onChange={(e) => this.handleChange(e)} />
                                    <label htmlFor="password"><HiLockClosed /> Nouveau mot de passe</label>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <input type="password" className={"form-control "+errorClass(this.state.formErrors.password_confirm)} id="password_confirm" name="password_confirm" placeholder="Confirmation du mot de passe" value={this.state.formFields.password_confirm} onChange={(e) => this.handleChange(e)} />
                                    <label htmlFor="password_confirm"><HiLockClosed /> Confirmation mot de passe</label>
                                </div>
    
                                <button className="mt-4 w-100 btn btn-lg btn-primary" onClick={() => this.handleSubmit()}>Valider</button>
                                
                        </main>
                    </div>
                </div>
            )

        }
        else {
        return (
            <div id="fmt_login" className="bg_blur h-100 container"> 
            

                <main className="form-signin">
                    
                        <div class="alert alert-icon alert-warning" role="alert">
                            <i class="fas fa-exclamation-triangle"></i> Ceci est votre première connexion, merci de bien vouloir changer votre mot de passe.
                        </div>
                        <div id="error_dom" className="alert alert-danger text-start d-none"></div>

                        <div className="form-floating mt-4">
                            <input type="password" className={"form-control "+errorClass(this.state.formErrors.password)} id="password" name="password" placeholder="Mot de passe" value={this.state.formFields.password}  onChange={(e) => this.handleChange(e)} />
                            <label htmlFor="password"><HiLockClosed /> Nouveau mot de passe</label>
                        </div>
                        <br />
                        <div className="form-floating">
                            <input type="password" className={"form-control "+errorClass(this.state.formErrors.password_confirm)} id="password_confirm" name="password_confirm" placeholder="Confirmation du mot de passe" value={this.state.formFields.password_confirm} onChange={(e) => this.handleChange(e)} />
                            <label htmlFor="password_confirm"><HiLockClosed /> Confirmation mot de passe</label>
                        </div>

                        <button className="mt-4 w-100 btn btn-lg btn-primary" onClick={() => this.handleSubmit()}>Valider</button>
                        
                </main>
            </div>
        )
        }
    }  
    }

export default function(props) {
    const navigation = useNavigate();
    const location = useLocation()
    return <AuthChangePwd {...props} navigation={navigation} location={location} />;
}
