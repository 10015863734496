
export const CampaignUserStatus = (status) => {
    let $txt = '';
    switch (status) {
      case 'candidature_en_cours':
        $txt = 'candidature_en_cours'
        break;
      case 'refus':
        $txt = 'refus'
        break;
      case 'non_valide':
        $txt = 'program_status_encours'
        break;
      case 'attente':
        $txt = 'program_status_encours'
        break;
      case 'valide':
        $txt = 'program_status_valide'
        break;
      case 'valide_colis_a_envoyer':
        $txt = 'program_status_colis_envoye'
        break;
      case 'colis_envoye':
        $txt = 'program_status_colis_envoye_ter'
        break;
      case 'colis_non_recu':
        $txt = 'program_status_colis_non_recu_notification'
        break;
      case 'deal_en_cours':
        $txt = 'program_status_deal_en_cours'
        break;
      case 'cloturer':
        $txt = 'closed'
        break;
      case 'programme_annule':
        $txt = 'programme_annule'
        break;
      default:
        $txt = '';
        break;
    }
    return $txt;
  }

 export const truncateText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
  
    return text.substr(0, length) + '\u2026'
  }
  
  export const prettyDay = (time) => {
    if(time === undefined) return '';
    const formated_hour= new Date(time.replace(/-/g, "/"));
    return formated_hour.toLocaleDateString(navigator.language, {weekday: 'short'});
  }
  export const prettyDate2 = (time) => {
    if(time === undefined) return '';
    const formated_hour= new Date(time.replace(/-/g, "/"));
    return formated_hour.toLocaleTimeString(navigator.language, {hour: '2-digit',minute:'2-digit'});
  }