import React from 'react';

import ContactCard from '../Components/ContactCard';
import Header from '../Components/Header';
import Fetcher from '../Helpers/Fetcher';

class Contacts extends React.Component{
  constructor(props) {  
    super(props);
    this.state = {
      error: false,
      isLoaded: false,
      items: [], 
      showActionSheet: false,
      setShowActionSheet: false
    }
  }
  
  componentDidMount() {
    this.fetchContactApi();
    /*const searchbar = document.querySelector('ion-searchbar')!;
    searchbar.addEventListener('ionCancel', this.handleCancel);*/
  }
  fetchContactApi = () =>{    
    new Fetcher().callApi({
        api_path:'/contact/get',
        api_data:{  
          "nb_items": 2
        },
        method:'POST',
    }).then((r) => {
        if(r.status === 'success'){
            this.setState({isLoaded: true,items: r.results});
        }
        else {
          this.setState({isLoaded: true,error:true})
        }
    });
  }  
  searchContact(e){
    const query = e.target.value.toLowerCase();
    this.state.items.forEach(item => {
      const myElement = document.getElementById("con_"+item.uid_user);
      if(item.contact_first_name.toLowerCase().indexOf(query) > -1 || item.contact_last_name.toLowerCase().indexOf(query) > -1 || item.contact_company.toLowerCase().indexOf(query) > -1) myElement.classList.remove("hidden");
      else myElement.classList.add("hidden");
    });
  }
  handleCancel = () => {
    this.state.items.forEach(item => {
      const myElement = document.getElementById("con_"+item.uid_user);
      myElement.classList.remove("hidden");
    });
  }
  render(){
    if(this.state.isLoaded){
      if(this.state.items.length === 0){
        return (
        <div id="fmt_contact" className="container general_spacing h-100 bg_lightblue">       
          <Header Title="Mes contacts" />
            <div>  
              <span>Aucun contact</span>
            </div>  
        </div>
        )
      }
      else return this.renderContact()
    }
    else{
      return (       
        <div id="fmt_contact" className="container general_spacing h-100 bg_lightblue">    
          <Header Title="Mes contacts" />
            <div>  
              <span>Chargement...</span>
            </div>  
        </div>
        );
    }
    
  }
  renderContact(){
    return (
        <div id="fmt_contact" className="container general_spacing h-100 bg_lightblue">   
          <Header Title="Mes contacts" />
          <div>
            <input type="search" className="form-control" onChange={(e) => this.searchContact(e)} />
          </div>

          <div>
            <div className="conCardsCont mt-4 pb-5">
                <div className="conCards pb-5">
                  {this.state.items.map((item,key) => {
                      return (
                        <ContactCard key={key} item_key={key} item={item} />
                      )
                  })}
                  </div>
            </div>

          </div>
      </div>
    );  
  }

};

export default Contacts;
