import React from 'react';

import Header from '../Components/Header';
import Fetcher from '../Helpers/Fetcher';
import { Dialog } from '@capacitor/dialog';
import {getLocalStorageItem} from '../Helpers/Storage';

class Quizz extends React.Component{
  constructor(props) {
    super(props);
    this.state = { 
      error: false,
      isLoaded: false,

      showAlert1: false,

      isopen: false,
      submitted: false,
      nb_questions: 0,
      questions:[],
      txt_thanks: '',
      txt_intro: '',
      txt_open: '',
      
      selected: false,
      selectedAnswers: [],
    };

  } 
  componentDidMount = () =>{
    //Savety Check if distributeur
    getLocalStorageItem('fmt_user_info').then((r)=>JSON.parse(r)).then((res) => {
      if(res !== null){
          if(res.type_company === 'distributeur') this.fetchQuizzApi();
      }
    });
  }
  fetchQuizzApi(){
    new Fetcher().callApi({
        api_path:'/quizz/get',
        api_data:{},
        method:'POST',
    }).then((r) => {
        if(r.status === 'success'){
            this.setState({
                isLoaded: true,
                txt_intro: r.results.txt_intro,
                txt_open: r.results.txt_open,
                txt_thanks: r.results.txt_thanks,
                submitted: r.results.submitted,
                isopen: r.results.isopen,          
                nb_questions: r.results.nb_questions,
                questions: r.results.questions
            });
        }
        else {
            this.setState({isLoaded: true,error:true})
        }
    });
  }  
  onAddItem = (ind, v) => {
      let sa = this.state.selectedAnswers;
      sa[ind] = v;
    this.setState({selectedAnswers:sa});
  }
  submitQuizz = () =>{
    if(this.state.nb_questions === Object.values(this.state.selectedAnswers).length){

        new Fetcher().callApi({
            api_path:'/quizz/save_responses',
            api_data:{"q_responses": this.state.selectedAnswers},
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({submitted:true});
            }
            else {
                this.setState({isLoaded: true,error:true})
            }
        });
    }
    else this.showAlert();
  } 
  showAlert = async () => {
    await Dialog.alert({
      title: 'Attention',
      message: 'Merci de répondre à toutes les questions.',
    });
  };
  render(){
    if(this.state.isLoaded && !this.state.submitted && this.state.isopen) return this.renderQuizz();
    else if(!this.state.isopen){
      return (      
        <div id="fmt_quizz" className="container general_spacing h-100 bg_lightblue">     
            <Header Title="Quizz" />
            <div className="row">  
              <div className="col text-center">{this.showHtml(this.state.txt_open)}</div>
            </div>  
        </div>
        );
    }
    else if(this.state.submitted){
      return (    
        <div id="fmt_quizz" className="container general_spacing h-100 bg_lightblue">         
            <Header Title="Quizz" />
            <div className="row">  
                <div className="col text-center">{this.showHtml(this.state.txt_thanks)}</div>
            </div>  
        </div>
        );
    }
    else{
      return (      
        <div id="fmt_quizz" className="container general_spacing h-100 bg_lightblue">      
            <Header Title="Quizz" />
            <div className="row">  
                <div className="col text-center">Chargement du quizz...</div>
            </div>  
        </div>
        );
    }
  }
  showHtml(body) {
    //let body = '<p>Sample text with <strong>bold</strong></p>'
    return (
     <div dangerouslySetInnerHTML={{__html: body}} />
    )
  }
  renderQuizz(){
    return (
        
      <div id="fmt_quizz" className="container general_spacing h-100 bg_lightblue">   

            <Header Title="Quizz" />
            <div  className="evMargin">
                <div className="txt_intro pb-4">{this.showHtml(this.state.txt_intro)}</div>

                {
                    this.state.questions.map((item,key) => {
                        return (
                            <div key={'ig_'+key} className="input-group mb-3">
                                <label>{item.q}</label>

                                {item.c.map((rep,k) => {
                                    return (
                                        <div className="form-check"  key={key+'_'+k}>
                                            <input className="form-check-input" type="radio" id={k+rep} value={rep} name={key}  onChange={(e) => this.onAddItem(item.l,rep)} />
                                            <label className="form-check-label" htmlFor={k+rep}>{rep}</label>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        )
                    })
                }
            
                <br />
                <div className="d-grid mb-5">
                    <button className="btn btn-primary btn-block" onClick={() => this.submitQuizz()}>Envoyer</button>
                </div>
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    );  
  }

};

export default Quizz;