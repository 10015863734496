import React from 'react';
import CalendarCard from '../Components/CalendarCard';
import CalendarModalCard from '../Components/CalendarModalCard';

import Header from '../Components/Header';
import {getLocalStorageItem} from '../Helpers/Storage';
import Fetcher from '../Helpers/Fetcher';
import { LocalNotifications } from '@capacitor/local-notifications';
import Modal from 'react-bootstrap/Modal';


class Calendar extends React.Component {

  constructor(props) {  
    super(props);
    this.ntbe = false;
    this.state = {
      //ntbe:false,
      error: false,
      isLoaded: false,
      items:[],
      item:[],
      time: new Date().toLocaleTimeString(navigator.language, {hour: '2-digit',minute:'2-digit'}),
      content: '',
      showDistributeur:false,
      showFormateur:false,
      //modal
      show:false,
      modalContent:'',
      title:(this.props.type !== 'free_formation')?'Calendrier':'Autres formations disponibles'
    }; 
    
  }

  componentDidMount() {
    this.fetchCalendarApi();
    this.intervalID = window.setInterval(
      () => this.tick(),
      1000*60
    );

    
    getLocalStorageItem('fmt_user_info').then((r)=>JSON.parse(r)).then((res) => {
      if(res !== null){
          if(res.type_company === 'distributeur') this.setState({showDistributeur:true});
          if(res.formateur === true) this.setState({showFormateur:true});
      }
    });
  }
  tick() {
    this.ntbe = false;
    this.setState({
      time: new Date().toLocaleTimeString(navigator.language, {hour: '2-digit',minute:'2-digit'})
    });
  }
  ionPageWillLeave(){
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  scrollToEl(){
    if(this.state.isLoaded){
      if(typeof(document.getElementsByClassName("scrollToEl")[0]) !== 'undefined'){
        setTimeout(()=>{
          const rect = document.getElementsByClassName("scrollToEl")[0];
          rect.scrollIntoView();
        },250);
      }
      /*if(typeof(document.getElementsByClassName("oldMeeting")[0]) !== 'undefined'){
        setTimeout(()=>{
          const rect = document.getElementsByClassName("oldMeeting")[0].getBoundingClientRect();
          ctnr.scrollToPoint(0,rect.y-scrollOffset,500);
        },250);
      }*/
    }
  }
  fetchCalendarApi(){
    let $api_path = '/calendar/get';
    if(this.props.type === 'free_formation') $api_path = '/calendar/list_available_formation';
    
    new Fetcher().callApi({
        api_path:$api_path,
        api_data:{},
        method:'POST',
    }).then((r) => {
        if(r.status === 'success'){
          this.setState({
            isLoaded: true,
            items: r.results
          }, () => {
            this.scrollToEl();
          });
          
          if(this.props.type !== 'free_formation') {    
              getLocalStorageItem('fmt_receiveCalendarNotification').then((res) => {
                if(res === null || res === 'on' ) this.addNotifications(r.results);
              });
          }


        }
        else {
          this.setState({
            isLoaded: true,
            error:true
          });  
        }
    });


      

  }  
  addNotifications(noti_days){
      //console.log('-- noti_days --',noti_days);
      const tsNow = new Date().getTime();
      
      noti_days.forEach((noti_day,noti_daykey) => {
        //console.log('-- noti_day --',noti_day);
        noti_day['cal_items'].forEach((noti_item,noti_key) => {
          //console.log('-- cal_timestamp_start --',noti_item.cal_timestamp_start);
          //console.log('-- tsNow --',tsNow);
          if (noti_item.cal_timestamp_start > tsNow) {
            //console.log('-- create noti --',noti_item);
            const host_or_attendee = noti_item.host_or_attendee;
            let cal_time = parseInt(noti_item.cal_timestamp_start);
            let sd = new Date(cal_time);
            sd.setMinutes( sd.getMinutes() +  new Date().getTimezoneOffset() - 5 ); //Add timezone offset + trigger 5 minutes before
            
            //sd.setMinutes( sd.getMinutes() - 5 ); //trigger 5 minutes before
            //console.log('-- noti trigger time --',sd);
            let noti_body = '';
            if(host_or_attendee === 'host') noti_body = noti_item.attendee_first_name+' '+noti_item.attendee_last_name+' - '+noti_item.attendee_company;
            else {
              if(noti_item.host_first_name !== undefined) noti_body = noti_item.host_first_name+' '+noti_item.host_last_name+' - '+noti_item.host_company;
              else noti_body = '';
            }

            LocalNotifications.schedule({
              notifications: [
                {
                  title: noti_item.cal_title,
                  body: noti_body,
                  id: noti_daykey+noti_key,
                  schedule: { at: sd},
                  extra: {
                    calendarType : 'formation',
                    calendarUrl: '/calendar'
                  }
                }
              ]
            });
          }
        });
      });
  }
  doRefresh = (event) => {
    //console.log('Begin async operation');
    setTimeout(() => {
      //console.log('Async operation has ended');
      this.fetchCalendarApi();
      
      event.detail.complete();
    }, 2000);
  }
  getNiceDateName(dateStr){
    var date = new Date(dateStr);
    const string = date.toLocaleDateString(navigator.language, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }); 
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  nowTimeBloc(tsStart,tsEnd,tsDayStart,tsDayEnd, posEl, nbEl){
    const date = new Date(); //new Date().getTimezoneOffset();    
    const tsNow = date.getTime() ;

    tsStart = parseInt(tsStart);
    let sd = new Date(tsStart);
    sd.setMinutes( sd.getMinutes() +  new Date().getTimezoneOffset());
    tsStart = sd.getTime();

    tsDayEnd = parseInt(tsDayEnd);
    let sd_end = new Date(tsDayEnd);
    sd_end.setMinutes( sd_end.getMinutes() +  new Date().getTimezoneOffset());
    tsDayEnd = sd_end.getTime();
    
    console.log(tsStart);
    if(nbEl === 0 || this.ntbe) return;

    //console.log('if', tsDayStart < tsNow && tsNow < tsDayEnd && tsNow < tsStart);
    
    if(tsNow < tsDayEnd && tsNow < tsStart ) {
      this.ntbe = true;
      const timeNextMetting = Math.abs(tsStart - tsNow);
      const minutes = Math.floor((timeNextMetting/1000));

      return(
        <div className="nowTimeBloc scrollToEl">
          <div className="row calNowBloc align-items-center">
              <div className="col-2">
                <div className="calNowBlocLine">
                 <span></span>
                </div>
              </div>
              <div className="col-3 calNowBlocCurTime">
                {this.state.time}
              </div>
              <div className="col-7 calNowBlocNextMeeting">
                Prochain meeting dans <br />
                <b>{this.timeConvert(minutes)}</b>
              </div>
          </div>
        </div>
    )
    }
    
  }
  timeConvert(n) {
    var num = n;

    var weeks    = Math.floor(num / 604800);
    var days    = Math.floor(num / 86400);
    var hours   = Math.floor(num / 3600 % 24);
    var minutes = Math.floor(num / 60 % 60);

    if(weeks > 2) return weeks + " semaines";
    else if(weeks <= 2 && days > 0) return days + "j " + hours + "h " + minutes + "min";
    else if(days <= 0 && hours > 0) return hours + "h" + minutes + "min";
    else return minutes + "min";
  }
  render(){
    return this.state.isLoaded ? this.renderCal() : (   
      <div id="fmt_calendar" className="container">  
        <Header Title="Calendrier" rightCta={{showDistributeur:this.state.showDistributeur,showFormateur:this.state.showFormateur}}  />
          <div>  
            <span>chargement...</span>
          </div>  
      </div>
      );
      
  }
  handleClose = (e) => {  
    this.ntbe = false;
    this.setState({show:false,modalContent:''});
  }
  handleShow = (e,cal_item) => {  
    this.ntbe = false;
    this.setState({
      show:true,
      modalContent:cal_item
    }); 
  }
  renderCal(){
    return (
      <div  id="fmt_calendar" className="container general_spacing h-100">   
        <Header Title={this.state.title} rightCta={
          (this.props.type !== 'free_formation')?
          {
            showDistributeur:this.state.showDistributeur,
            showFormateur:this.state.showFormateur
          }:''
        } /> 
        
        
        <div id="calCardsCont" key="ctnr_ccc" >

          <Modal show={this.state.show} centered onHide={(e)=> this.handleClose(e)} animation={false}>
            <Modal.Body>
              <CalendarModalCard item={this.state.modalContent} pHandleClose={(e)=> this.handleClose(e)} />
            </Modal.Body>
          </Modal>
          
          {(() => {
                if(this.state.items.length !== 0){
                  return(
                    Object.values(this.state.items).map((item,key) => {
                      var i = 0;
                      const des = item.day_timestamp_start;
                      const ded = item.day_timestamp_end;
                      const nbEl = item.nb_future_meetings;
                      return (
                        <div key={"ccc_"+key} id={"ccc_"+key}>
                          
                          <h5>{this.getNiceDateName(item.date)}</h5>
                          <div className="calCards">
                            {item.cal_items.map((cal_item, cal_key) => {
                              const ces = cal_item.cal_timestamp_start;
                              const ced = cal_item.cal_timestamp_end;
                              i++;
                              return (
                                <div key={"cccc_"+cal_key}>
                                  {this.nowTimeBloc(ces,ced,des,ded,i,nbEl)}
                                  <div key={"cc_"+cal_key} id={"cc_"+cal_key} onClick={(e)=> this.handleShow(e,cal_item)}>
                                    <CalendarCard item={cal_item} ces={ces} ced={ced} type={this.props.type} />
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        )
                        
                    })
                  )
              }
              else{
                return (
                  <p>Aucun meeting</p>
                )
              }
                  
            })()}

          </div>
      </div>
    )
  }
};

export default Calendar;
