import React from 'react';
import {Link} from "react-router-dom";
import { Device } from '@capacitor/device';
import Fetcher from '../Helpers/Fetcher';
import { showToast} from '../Helpers/Toast';
import {getLocalStorageItem} from '../Helpers/Storage';

import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { Camera } from '@capacitor/camera';

import ContactCard from '../Components/ContactCard';
import Header from '../Components/Header';


class QrScanner extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        error: false,
        isLoaded: false,
        items:[],
        item : '',
        errorMsg:'',
        formateur: false
      };    
   }
    componentDidMount() {
      getLocalStorageItem('formateur').then((r)=>JSON.parse(r)).then((res) => {
          if(res !== null){
              if(res.formateur === true) this.setState({formateur:true});
          }
      });
      
      this.logDeviceInfo().then((platform) => { 
          if(platform !== 'web') { 
            this.openScan();
          }
          else this.setState({
              error:true,
              errorMsg:'Indisponible sur web'
          })
      });
    }
    componentWillUnmount() {
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
      document.body.style.background = "";
    }
    logDeviceInfo = async () => {
        const info = await Device.getInfo();
        return info.platform;
    }
    openScan = async () => {
      //Flush items to remove DOM block  
      this.setState({items:[]});
      //check perm
      this.checkPerms().then(result => { if (result === true) { 
        this.startScan();
      }
      else{ alert('Nous avons besoin de votre caméra pour le scan de QR code. Rendez vous dans Réglages de votre téléphone > Recherchez “FORMATECH“ > Activez “App. photo“') } });
        
    }
    startScan = async () => {
      console.log('-- start scan --');
      //css hacks
      document.body.style.background = "transparent";

      BarcodeScanner.hideBackground(); 

      const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] }); // start scanning and wait for a result
    
      console.log('-- start scan after res --', result);
      // if the result has content
      if (result.hasContent) {
        document.body.style.background = "";

        //LOG in DB
        new Fetcher().callApi({
          api_path:'/scan/contact',
          api_data:{
            user_code:result.content
          },
          method:'POST',
        }).then((r) => {
          console.log('-- res --', r);
            if(r.status === 'success'){
              console.log('-- items --', r.results);
              console.log('-- nb items --', r.results.length);
              this.setState({
                isLoaded: true,
                items: r.results
              });
            }
            else showToast("Une erreur est survenue");
        });
      }      
  }
  checkPerms = async () => {
    
    const perm = await Camera.checkPermissions();
    console.log('-- perm --',perm);
    if(perm.camera === 'granted'){
      return true
    }
    else {
      const permR = await Camera.requestPermissions({permissions:['camera']});
      
      if(permR.camera === 'granted'){
        console.log('-- perm --',permR);
        return true
      }
      else { 
        console.log('-- perm --',permR);
        return false;
      }
    }

  }
  
    
            
    

  render(){
      //const { error, isLoaded, items } = this.state;
     // console.log(this.state.error);
      if (this.state.error) {
        return (
            <div id="fmt_scanner" className="container general_spacing h-100">   
                <Header Title="Scan QR code" />
                <div>
                  <p className="bg_light-red evMargin evPadding">{this.state.errorMsg}</p>
                </div>
            </div>
        )
      }
      else if (this.state.formateur){ // && this.state.items.length == 0) {
        return (
          <div id="fmt_scanner" className="container general_spacing h-100">    
          <Header Title="Scan QR code" />
                <div>
                  <div  className="d-grid  evPadding">
                    <p className="text-center">Si vous êtes formateur en session de formation : </p>
                    <Link to="/formations_scan" className="btn btn-primary btn-block">Scannez vos participants formation</Link>
                    <br /><br /><br />
                    <p className="text-center">Sinon scannez un nouveau contact : </p>
                    <button onClick={() => this.openScan()} className="btn btn-primary btn-block">Nouveau participant</button>
                  </div>

                </div>
            </div>
        )
      }
      else {
        return (
            <div id="fmt_scanner" className="container general_spacing h-100">    
              <Header Title="Scan QR code" />

              <div>
                {(() => {
                  if(this.state.items.length !== 0){
                      return (
                        <div className="conCardsCont evMargin">
                          <h2>Contact ajouté :</h2>
                          <div className="conCards">
                            {this.state.items.map((item,key) => {
                                return (
                                  <ContactCard key={key} item_key={key} item={item} />
                                )
                            })}
                          </div>
                          <div className="d-grid">
                            <button onClick={() => this.openScan()} className="btn btn-primary btn-block">Nouveau scan</button>
                          </div>
                        </div> 
                      )
                    }
                  })()}

              </div>
          </div>
      );
    }
  } 
};


export default QrScanner;