import React from 'react';
import {FaMapMarkerAlt} from 'react-icons/fa';
import {BsFillPersonFill} from 'react-icons/bs';
import {BsClockFill} from 'react-icons/bs';
import {RiBuilding3Fill} from 'react-icons/ri';
import CloseButton from 'react-bootstrap/CloseButton';

class CalendarModalCard extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {}

    }
    getMeetingState(tsStart,tsEnd){
        var tsNow = new Date().getTime()
        if(tsNow > tsStart && tsNow < tsEnd ) return 'currentlyMeeting scrollToEl';
        else if(tsNow > tsEnd) return 'oldMeeting'; 
        else return 'futureMeeting';
    }
    
    prettyDate2(time){
        //var date = new Date(time);
        const formated_hour= new Date(time.replace(/-/g, "/"));
        return formated_hour.toLocaleTimeString(navigator.language, {hour: '2-digit',minute:'2-digit'});
    }

    render(){
        return (         
            <div className={"calCardModal passed "+ this.getMeetingState(this.props.ces,this.props.ced)+" "+ this.props.item.cal_type} >  
                <div className="row align-items-center my-2">
                    <div className="col">
                        <div className="calCardBloc">
                            <div className="calCardTitle">
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <b>{this.props.item.cal_title}</b>
                                    </div>
                                    <div className="">
                                        <CloseButton onClick={this.props.pHandleClose} /> 
                                    </div>  
                                </div>
                            </div>
                            <div className="calCardBody">
                                
                                <div className="py-1">
                                    <span className="align-ico"><BsClockFill  className="ico" /></span> 
                                    <span className="align-ico">{this.prettyDate2(this.props.item.cal_start_date)} - {this.prettyDate2(this.props.item.cal_end_date)}</span>
                                </div>

                                <div className="py-1">
                                    {this.props.item.cal_location !== '' ?  ( <>
                                    <span className="align-ico"> <FaMapMarkerAlt  className="ico" /> </span> 
                                    <span className="align-ico">{this.props.item.cal_location} </span></>
                                    ) :(<></>)
                                }
                                </div>
                                <div className="py-1">
                                    <span className="align-ico"><BsFillPersonFill  className="ico" /> </span>
                                    <span className="align-ico">{this.props.item.host_first_name} {this.props.item.host_last_name}
                                    </span>
                                </div>
                                <div className="py-1"></div>
                                <div className="py-1">
                                    <span className="align-ico"><RiBuilding3Fill  className="ico" /> </span>
                                    <span className="align-ico">{this.props.item.host_company}
                                    </span>
                                </div>

                                <div  className="my-3" dangerouslySetInnerHTML={{__html: this.props.item.cal_description_full}} />
                            </div>
                        
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default CalendarModalCard;